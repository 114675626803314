import React, { useState, useEffect } from 'react'
import { Container, Input } from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import Logo from '../../assets/images/logo.png'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const SpecialitiesTable=(props)=>{

	const { t, i18n } = useTranslation();

	const isLoading = props.loading === true ? true : false;
 	const [pageNo, setPageNo] = useState(null);

 	useEffect(()=>{
	    if(Math.ceil(props.total/5) < pageNo){
	      setPageNo(Math.ceil(props.total/5));
	      props.getSpecialitiesList(Math.ceil(props.total/5));
	    }
	}, [props.total])

	 const [columns, setColumns] = useState([
	 	{ key: "doc-row", dataField: 'doc-row', text:'',
	 	style:{maxWidth:80,textAlign:'center'},
          formatter: (rowContent, row) => {
        		return (
        			<div className="">
		                <img
						  src={row.img ? row.img : Logo}
		                  alt="Speciality Logo"
		                  className="profile-img"
		                  style={{maxHeight:50,maxWidth:90}}
		                />
	              	</div>
        		)
        	}
    	},{
    	  key:'name',
	      dataField: 'name',
	      text: t('Name'),
	      style: {fontWeight: 600}
	    }, {
	      key:'description',
	      dataField: 'description',
	      text: t('Description'),
	      headerStyle:{width:'40%'},
	      formatter: (rowContent, row) => {
	      	if(row.description !== 'undefined')
	      		return row.description
	      	else
	      		return null
	      }
	    }, {
	      key:'active',
	      dataField: 'active',
	      text: t('Active'),
	      headerStyle:{width:80,textAlign:'center'},
	      style:{maxWidth:80,textAlign:'center'},
	      formatter: (rowContent, row) => {
        		return (
        			<Input
	                  type="checkbox"
	                  className="rounded-check"
	                  id="remember"
	                  name='remember'
	                  disabled={true}
	                  checked={row.active}
	                  style={{borderRadius:0, width:'1.22em', height:'1.2em',padding:7, borderColor:'#DA1021', borderWidth:'.15rem',opacity:0.9}}
	                />
        		)
          }
	    }, {
	      key:'actions',
	      dataField: 'actions',
	      text: t('Actions'),
	      headerStyle:{textAlign:'center'},
	      style: {textAlign:'center'},
	      formatter: (rowContent, row) => {
        		return (
        			<div className='text-primary'>
        				<span><i style={{cursor:'pointer'}} onClick={()=> props.onEditClick(row)} className='bx bx-edit bx-sm'></i></span>
        				<span><i style={{cursor:'pointer'}} onClick={()=> props.onDeleteClick(row.id)}className='bx bx-trash bx-sm'></i></span>
        			</div>
        		)
          }
	    }
	])

	useEffect(()=>{
		setColumns(columns)
	}, [JSON.parse(JSON.stringify(props.specialityPics))])

	const pagination = paginationFactory({
		sizePerPage: 20,
	    page:pageNo ? pageNo : 1,
	    withFirstAndLast: false,
	    alwaysShowAllBtns: true,
	    hideSizePerPage:true,
	    totalSize:props.total
	});

	const onTableChange=(name, e)=>{
	    setPageNo(e.page);
	    props.getSpecialitiesList(e.page)
	}


	return(
		<Container fluid={true} className='table-container'>
            <BootstrapTable 
                keyField='id' 
                data={props.specialitiesList} 
                columns={columns} 
                pagination={pagination}
                bordered={false}
                classes="table-common products-table"
                headerClasses="header-class-dark-table"
                overlay={ 
                  overlayFactory({ 
                    spinner: true, 
                    styles: { 
                      spinner: (base) => ({...base, '& svg circle': {stroke: '#DA1021'}, width:'50px'}),
                      overlay: (base) => ({...base, background: 'rgba(255, 255, 255, 0.9)'})
                    } 
                  }) 
                }
                onTableChange={ onTableChange }
                remote
                loading={isLoading}
                noDataIndication={<></>}
            />
        </Container>
	)
}

export default SpecialitiesTable;