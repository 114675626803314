import React from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const SearchBar = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <Container fluid={true}>
      <Row className="mt-4 mb-4">
        <Col className="d-flex searchbar-input">
          <div>
            <i className="bx bx-search-alt"></i>
          </div>
          <Input
            type="text"
            name={props.name.toLowerCase()}
            id={props.name.toLowerCase()}
            className="form-control form-control-login"
            placeholder={t('Search') + ' ' + props.name}
            value={props.searchValue}
            onChange={(e) => props.setSearchValue(e.target.value)}
          />
        </Col>
        <Col className="ms-auto text-end">
          {props.name !== t('ExcludedCollections') ? (
            <Button
              onClick={props.onClick ? props.onClick : null}
              color="primary"
            >
              {t('Add')} {t(props.btnName)}
            </Button>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchBar;
