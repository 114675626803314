import {
  GET_DOCUMENTS_LIST_REQUEST,
  GET_DOCUMENTS_LIST_SUCCEED,
  GET_DOCUMENTS_LIST_FAILED,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCEED,
  ADD_DOCUMENT_FAILED,
  EDIT_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_SUCCEED,
  EDIT_DOCUMENT_FAILED,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCEED,
  DELETE_DOCUMENT_FAILED,
  GET_DOCUMENT_PRODUCT_LIST_REQUEST,
  GET_DOCUMENT_PRODUCT_LIST_SUCCEED,
  GET_DOCUMENT_PRODUCT_LIST_FAILED,
  GET_DOCUMENT_DETAIL_REQUEST,
  GET_DOCUMENT_DETAIL_SUCCEED,
  GET_DOCUMENT_DETAIL_FAILED,
  ADD_PRODUCT_DOCUMENT_REQUEST,
  ADD_PRODUCT_DOCUMENT_FAILED,
  ADD_PRODUCT_DOCUMENT_SUCCEED,
  EDIT_PRODUCT_DOCUMENT_REQUEST,
  EDIT_PRODUCT_DOCUMENT_FAILED,
  EDIT_PRODUCT_DOCUMENT_SUCCEED,
  DELETE_PRODUCT_DOCUMENT_REQUEST,
  DELETE_PRODUCT_DOCUMENT_FAILED,
  DELETE_PRODUCT_DOCUMENT_SUCCEED,
  ADD_DOCUMENT_SPECIALITY_REQUEST,
  ADD_DOCUMENT_SPECIALITY_FAILED,
  ADD_DOCUMENT_SPECIALITY_SUCCEED,
  EDIT_DOCUMENT_SPECIALITY_REQUEST,
  EDIT_DOCUMENT_SPECIALITY_SUCCEED,
  EDIT_DOCUMENT_SPECIALITY_FAILED,
  DELETE_DOCUMENT_SPECIALITY_REQUEST,
  DELETE_DOCUMENT_SPECIALITY_FAILED,
  DELETE_DOCUMENT_SPECIALITY_SUCCEED,
  CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST,
  CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED,
  CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED,
  ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST,
  ADD_DOCUMENT_ADDITIONAL_LINK_FAILED,
  ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
  EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST,
  EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
  EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED,
  DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST,
  DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED,
  DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
  CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST,
  CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED,
  CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED,
  DUPLICATE_DOCUMENT_FAILED,
  DUPLICATE_DOCUMENT_SUCCEED,
  DUPLICATE_DOCUMENT_REQUEST,
} from '../../ActionTypes';

import request from '../../../helpers/requestHelper';

export const getDocumentsList = (page) => async (dispatch) => {
  dispatch({ type: GET_DOCUMENTS_LIST_REQUEST });

  try {
    const res = await request({
      url: `documents?page=${page}&limit=20`,
      auth: true,
      method: 'GET',
    });

    if (res.code) {
      dispatch({
        type: GET_DOCUMENTS_LIST_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: GET_DOCUMENTS_LIST_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOCUMENTS_LIST_FAILED,
      payload: err,
    });
  }
};

export const addDocument = (Data) => async (dispatch) => {
  dispatch({ type: ADD_DOCUMENT_REQUEST });

  var data = new FormData();

  data.append('name', Data.name);
  data.append('description', Data.description);
  data.append('status', Data.status);
  data.append('order', Data.order);
  data.append('groups', Data.groups);
  data.append('showTitles', Data.showTitles);

  try {
    const res = await request({
      url: `documents`,
      auth: true,
      method: 'POST',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: ADD_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: ADD_DOCUMENT_SUCCEED,
        payload: res,
      });

      return res.id;
    }
  } catch (err) {
    dispatch({
      type: ADD_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const editDocument = (Data) => async (dispatch) => {
  dispatch({ type: EDIT_DOCUMENT_REQUEST });

  var data = new FormData();

  data.append('name', Data.name);
  data.append('description', Data.description);
  data.append('status', Data.status);
  data.append('order', Data.order);
  data.append('groups', Data.groups);
  data.append('showTitles', Data.showTitles);

  try {
    const res = await request({
      url: `documents/${Data.id}`,
      auth: true,
      method: 'PATCH',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: EDIT_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: EDIT_DOCUMENT_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const duplicateDocument = (id) => async (dispatch) => {

  dispatch({ type: DUPLICATE_DOCUMENT_REQUEST });

  try {
    const res = await request({
      url: `documents/${id}/duplicate`,
      auth: true,
      method: 'GET',
    });

    if (res.code) {
      dispatch({
        type: DUPLICATE_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: DUPLICATE_DOCUMENT_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: DUPLICATE_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT_REQUEST });

  try {
    const res = await request({
      url: `documents/${id}`,
      auth: true,
      method: 'DELETE',
    });

    if (res.code) {
      dispatch({
        type: DELETE_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: DELETE_DOCUMENT_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const getDocumentDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_DOCUMENT_DETAIL_REQUEST });

  try {
    const res = await request({
      url: `documents/${id}`,
      auth: true,
      method: 'GET',
    });

    if (res.code) {
      dispatch({
        type: GET_DOCUMENT_DETAIL_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: GET_DOCUMENT_DETAIL_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DOCUMENT_DETAIL_FAILED,
      payload: err,
    });
  }
};

// export const getDocumentProducts=(id)=> async dispatch=>{
// 	dispatch({type: GET_DOCUMENT_PRODUCT_LIST_REQUEST})

// 	try{
// 		const res = await request({
// 			url:`documents/${id}/products`,
// 			auth: true,
// 			method:'GET',
// 		});

// 		if(res.code){
// 			dispatch({
// 				type:GET_DOCUMENT_PRODUCT_LIST_FAILED,
// 				payload: res
// 			});
// 		}
// 		else{
// 			dispatch({
// 				type:GET_DOCUMENT_PRODUCT_LIST_SUCCEED,
// 				payload: res
// 			});
// 		}
// 	} catch (err){
// 		dispatch({
// 			type:GET_DOCUMENT_PRODUCT_LIST_FAILED,
// 			payload: err
// 		});
// 	}
// }

export const addProductDocument = (Data) => async (dispatch) => {
  dispatch({ type: ADD_PRODUCT_DOCUMENT_REQUEST });

  var data = new FormData();

  data.append('product', Data.product);
  data.append('associatedDocument', Data.associatedDocument);
  data.append('order', Data.order);
  data.append('page', Data.page);
  data.append('active', true);

  try {
    const res = await request({
      url: `documents/${Data.docId}/products`,
      auth: true,
      method: 'POST',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: ADD_PRODUCT_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: ADD_PRODUCT_DOCUMENT_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_PRODUCT_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const editDocumentProduct = (Data) => async (dispatch) => {
  dispatch({ type: EDIT_PRODUCT_DOCUMENT_REQUEST });

  var data = new FormData();

  data.append('product', Data.product);
  data.append('order', Data.order);
  data.append('page', Data.page);
  data.append('active', true);

  if (Data.associatedDocument)
    data.append('associatedDocument', Data.associatedDocument);

  try {
    const res = await request({
      url: `documents/${Data.docId}/product/${Data.id}`,
      auth: true,
      method: 'PATCH',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: EDIT_PRODUCT_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: EDIT_PRODUCT_DOCUMENT_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_PRODUCT_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const deleteProductDocument = (docId, productId) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_DOCUMENT_REQUEST });

  try {
    const res = await request({
      url: `documents/${docId}/product/${productId}`,
      auth: true,
      method: 'DELETE',
    });

    if (res.code) {
      dispatch({
        type: DELETE_PRODUCT_DOCUMENT_FAILED,
        payload: res,
      });
    } else {
      let data = {};
      data.id = productId;
      data.docId = docId;

      dispatch({
        type: DELETE_PRODUCT_DOCUMENT_SUCCEED,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_DOCUMENT_FAILED,
      payload: err,
    });
  }
};

export const addDocumentSpeciality = (Data) => async (dispatch) => {
  dispatch({ type: ADD_DOCUMENT_SPECIALITY_REQUEST });

  var data = new FormData();

  data.append('product', Data.product);
  data.append('coverImage', Data.coverImage);
  data.append('order', Data.order);
  data.append('contact', Data.contact);
  data.append('page', Data.page);
  data.append('specialty', Data.specialty);
  data.append('active', Data.active);

  try {
    const res = await request({
      url: `documents/${Data.docId}/specialties`,
      auth: true,
      method: 'POST',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: ADD_DOCUMENT_SPECIALITY_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: ADD_DOCUMENT_SPECIALITY_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_DOCUMENT_SPECIALITY_FAILED,
      payload: err,
    });
  }
};

export const editDocumentSpeciality = (Data) => async (dispatch) => {
  dispatch({ type: EDIT_DOCUMENT_SPECIALITY_REQUEST });

  var data = new FormData();

  data.append('product', Data.product);
  data.append('order', Data.order);
  data.append('contact', Data.contact);
  data.append('page', Data.page);
  data.append('specialty', Data.specialty);
  data.append('active', Data.active);

  if (Data.coverImage) data.append('coverImage', Data.coverImage);

  try {
    const res = await request({
      url: `documents/${Data.docId}/specialty/${Data.id}`,
      auth: true,
      method: 'PATCH',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: EDIT_DOCUMENT_SPECIALITY_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: EDIT_DOCUMENT_SPECIALITY_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_DOCUMENT_SPECIALITY_FAILED,
      payload: err,
    });
  }
};

export const deleteDocumentSpeciality = (docId, specId) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT_SPECIALITY_REQUEST });

  try {
    const res = await request({
      url: `documents/${docId}/specialty/${specId}`,
      auth: true,
      method: 'DELETE',
    });

    if (res.code) {
      dispatch({
        type: DELETE_DOCUMENT_SPECIALITY_FAILED,
        payload: res,
      });
    } else {
      let data = {};
      data.id = specId;
      data.docId = docId;

      dispatch({
        type: DELETE_DOCUMENT_SPECIALITY_SUCCEED,
        payload: data,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_DOCUMENT_SPECIALITY_FAILED,
      payload: err,
    });
  }
};

export const updateDocumentSpecialityOrder =
  (docId, order) => async (dispatch) => {
    dispatch({ type: CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST });

    var data = new FormData();
    data.append('ordering', JSON.stringify(order));

    try {
      const res = await request({
        url: `documents/${docId}/specialties/order`,
        auth: true,
        method: 'PATCH',
        data: data,
      });

      if (res.code) {
        dispatch({
          type: CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED,
          payload: res,
        });
      } else {
        dispatch({
          type: CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED,
        payload: err,
      });
    }
  };

export const addDocumentAdditionalLink = (Data) => async (dispatch) => {
  dispatch({ type: ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST });

  var data = new FormData();

  data.append('associatedDocument', Data.associatedDocument);
  data.append('title', Data.title);
  data.append('order', Data.order);
  data.append('page', Data.page);
  data.append('active', Data.active);

  if (Data.coverImage) data.append('coverImage', Data.coverImage);

  try {
    const res = await request({
      url: `documents/${Data.docId}/additionals`,
      auth: true,
      method: 'POST',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: ADD_DOCUMENT_ADDITIONAL_LINK_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_DOCUMENT_ADDITIONAL_LINK_FAILED,
      payload: err,
    });
  }
};

export const editDocumentAdditionalLink = (Data) => async (dispatch) => {
  dispatch({ type: EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST });

  var data = new FormData();

  data.append('title', Data.title);
  data.append('order', Data.order);
  data.append('page', Data.page);
  data.append('active', Data.active);

  if (Data.coverImage) data.append('coverImage', Data.coverImage);
  if (Data.associatedDocument)
    data.append('associatedDocument', Data.associatedDocument);

  try {
    const res = await request({
      url: `documents/${Data.docId}/additional/${Data.id}`,
      auth: true,
      method: 'PATCH',
      data: data,
    });

    if (res.code) {
      dispatch({
        type: EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED,
      payload: err,
    });
  }
};

export const deleteDocumentAdditionalLink =
  (docId, addLinkId) => async (dispatch) => {
    dispatch({ type: DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST });

    try {
      const res = await request({
        url: `documents/${docId}/additional/${addLinkId}`,
        auth: true,
        method: 'DELETE',
      });

      if (res.code) {
        dispatch({
          type: DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED,
          payload: res,
        });
      } else {
        let data = {};
        data.id = addLinkId;
        data.docId = docId;

        dispatch({
          type: DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
          payload: data,
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED,
        payload: err,
      });
    }
  };

export const updateDocumentAdditionalLinkOrder =
  (docId, order) => async (dispatch) => {
    dispatch({ type: CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST });

    var data = new FormData();
    data.append('ordering', JSON.stringify(order));

    try {
      const res = await request({
        url: `documents/${docId}/additionals/order`,
        auth: true,
        method: 'PATCH',
        data: data,
      });

      if (res.code) {
        dispatch({
          type: CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED,
          payload: res,
        });
      } else {
        dispatch({
          type: CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED,
          payload: res,
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED,
        payload: err,
      });
    }
  };
