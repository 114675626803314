import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ProductDocModal from './ProductDocModal'
import DeleteModal from '../main/DeleteModal'
import WarningModal from '../../components/main/WarningModal'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg' 
import { Icon } from '@iconify/react';

import { addProductDocument, deleteProductDocument, editDocumentProduct } from '../../redux/store/actions/documentActions'

import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const ProductDocs=(props)=>{
	
	const dispatch = useDispatch();
	const productsList = useSelector((state)=> state.product.productsList);

  	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formState, setFormState] = useState({
		product:'',
		associatedDocument:null,
		page:''
	})

	const saveProduct=(e)=>{
		e.preventDefault();
		setIsModalOpen(false);

		let data = {};

		data.docId = props.documentId;
	    data.product = formState.product;
	    data.page = formState.page;
	    data.order = props.products.length + 1;
	    
	    if(formState.associatedDocument && formState.associatedDocument.name)
	    	data.associatedDocument = formState.associatedDocument;

		if(formState.id){
			data.id = formState.id;
			dispatch(editDocumentProduct(data));
		}
		else
			dispatch(addProductDocument(data));

	   	setFormState({
	   		id:'',
			product:'',
			associatedDocument:null,
			page:''
		})

	}

	const handleDelete=()=>{
		let id = deleteId;
		setDeleteId()

		dispatch(deleteProductDocument(props.documentId, id))
	}

	const handleEdit=(item)=>{
		setFormState({
			product: item.product,
			associatedDocument: item.associatedDocumentFileName,
			id: item._id,
			page: item.page
		})
		setIsModalOpen(true)
	}

	const handleCustomDelete=(item)=>{
		if(props.specialtiesLinks.find((el)=> el.product === item.product))
			setIsWarningOpen(true)
		else
			setDeleteId(item._id)
	}

	const { t, i18n } = useTranslation();

	const tooltip = (
		<Tooltip id="tooltip">
		  <strong>{t('Missing file!')}</strong>
		</Tooltip>
	  );

	return(
		<>
			<Container fluid={true} className='p-3'>
				<Row><div className='fw-bold'>{t('Product Documents')} <span onClick={()=>setIsModalOpen(true)} className='ms-2 clickable'><PlusIcon/></span></div></Row>
				{props.products.length ?
					<Row className='mt-3'>
						<div className='table-container-product-docs'>
							<table style={{width:'100%'}} className='document-edit-table'>
								<thead>
									<tr>
										<th width='35%'>{t('Product')}</th>
										<th width='10%'>{t('Page')} #</th>
										<th width='40%'>{t('PDF File')}</th>
										<th width='15%'>{t('Actions')}</th>
									</tr>
								</thead>
								<tbody>
									{props.products.map((item, id)=>{
										return(
											<tr key={'doc-products-' +id}>
												<td><div className='form-control'>{productsList.find((el)=> el.id === item.product) ? productsList.find((el)=> el.id === item.product).name : item.product}</div></td>
												<td><div className='form-control'>{item.page}</div></td>
												<td>{item.associatedDocumentFileName ? <div className='form-control'>{item.associatedDocumentFileName}</div> : <div className='form-control d-flex justify-content-between align-items-center'><span>{t('No PDF file found')}</span><OverlayTrigger placement="top" overlay={tooltip}><Icon icon="material-symbols:warning-outline-rounded" color="#da1021" width="18" height="18"/></OverlayTrigger></div>}</td>
												<td>
													<div className='text-primary d-flex align-items-center justify-content-start'>
								        				<span><i onClick={()=> handleEdit(item)} className='bx bx-file clickable'></i></span>
														<span><i onClick={()=> handleCustomDelete(item)} className='bx bx-trash clickable'></i></span>
								        			</div>
												</td>												
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</Row>
				:''}
			</Container>
			<ProductDocModal 
				isOpen={isModalOpen} 
				setIsOpen={setIsModalOpen}
				formState={formState}
				setFormState={setFormState}
				onSaveClick={saveProduct}
			/>
			<DeleteModal
				isOpen={deleteId ? true : false} 
				setIsOpen={setDeleteId}
				name='product document'
				onDelete={handleDelete}
			/>
			<WarningModal
				isOpen={isWarningOpen} 
				setIsOpen={setIsWarningOpen}
			/>
		</>
	)
}

export default ProductDocs