import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Input } from 'reactstrap';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

import Select from 'react-select';

const BasicDetails = (props) => {
  const { t, i18n } = useTranslation();

  const onChangeHandler = (e) => {
    const { name, value, type } = e.target;
    props.setFormState({
      ...props.formState,
      [name]: type === 'checkbox' ? !props.formState.showTitles : value,
      [name]: type === 'checkbox' ? !props.formState.showContactTitles : value,
    });
  };

  return (
    <Container fluid={true} className="p-3 basic-details-container">
      <Row>
        <div className="fw-bold">{t('Basic Informations')} *</div>
      </Row>
      <form onSubmit={props.onSaveClick}>
        <Row>
          <div className="mt-3 col-md-6">
            <div>
              <label className="mb-2 p-0" htmlFor="title">
                {t('Title')} *
              </label>
            </div>
            <div>
              <Input
                type="text"
                name="title"
                id="title"
                className="form-control"
                value={props.formState.title}
                onChange={onChangeHandler}
                required
              />
            </div>
          </div>
          <div className="mt-3 col-md-6">
            <div>
              <label className="mb-2 p-0" htmlFor="status">
                {t('Status')} *
              </label>
            </div>
            <div>
              <Input
                type="select"
                name="status"
                id="status"
                className="form-control form-select"
                value={props.formState.status}
                onChange={onChangeHandler}
                required
              >
                <option hidden value="">
                  {t('Select a status')}
                </option>
                <option value="bozza">{t('System Draft')}</option>
                <option value="pubblicato">{t('Published')}</option>
                <option value="archiviato">{t('Archieved')}</option>
              </Input>
            </div>
          </div>
        </Row>
        <Row>
          <div className="mt-3 col-md-6">
            <div>
              <label className="mb-2 p-0" htmlFor="description">
                {t('Description')}
              </label>
            </div>
            <div>
              <Input
                type="text"
                name="description"
                id="description"
                className="form-control"
                value={props.formState.description}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          <div className="mt-3 col-md-6">
            <div>
              <label className="mb-2 p-0" htmlFor="order">
                {t('Order')}
              </label>
            </div>
            <div>
              <Input
                type="number"
                name="order"
                id="order"
                min={0}
                className="form-control"
                value={props.formState.order}
                onChange={onChangeHandler}
              />
            </div>
          </div>
        </Row>
        {props.formState.status === 'pubblicato' && props.formState.status && (
          <>
            <Row className="mt-4">
              <div className="fw-bold">{t('Group/s publication')} *</div>
            </Row>
            <Row>
              <div className="mt-3">
                <div>
                  <label className="mb-2 p-0" htmlFor="groups">
                    {t('Select group/s')}
                  </label>
                </div>
                <div>
                  <Select
                    isMulti
                    name="groups"
                    id="groups"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={props.formState.groups}
                    value={props.formState.groups}
                    options={props.groupsList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => `${option['id']}`}
                    onChange={(selectedOptions) =>
                      onChangeHandler({
                        target: { value: selectedOptions, name: 'groups' },
                      })
                    }
                  />
                </div>
              </div>
            </Row>
          </>
        )}
        <Row className="mt-4">
          <div className="col-md-6 mt-0 mt-md-2">
            <Input
              type="checkbox"
              className="rounded-check"
              id="showTitles"
              name="showTitles"
              style={{ width: '1.22em', height: '1.2em' }}
              checked={props.formState.showTitles}
              onChange={onChangeHandler}
            />
            <label
              className="form-check-label ms-2"
              style={{ marginTop: 1 }}
              htmlFor="showTitles"
            >
              {t('Show Titles')}
            </label>
          </div>
          <div className="col-md-6 mt-0 mt-md-2">
            <Input
              type="checkbox"
              className="rounded-check"
              id="showContactTitles"
              name="showContactTitles"
              style={{ width: '1.22em', height: '1.2em' }}
              checked={props.formState.showContactTitles}
              onChange={onChangeHandler}
            />
            <label
              className="form-check-label ms-2"
              style={{ marginTop: 1 }}
              htmlFor="showContactTitles"
            >
              {t('Show Contact Titles')}
            </label>
          </div>
          <div className="col-md-12 text-end basic-details-btn-grp">
            <Button color="primary" type="submit" className="me-2">
              {t('Save Document')}
            </Button>
            <Button type="button" onClick={props.onCancelButtonClick}>
              {t('Cancel')}
            </Button>
          </div>
        </Row>
      </form>
    </Container>
  );
};

export default BasicDetails;
