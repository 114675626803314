import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumb from '../../components/common/Breadcrumb';
import BasicDetails from '../../components/document/BasicDetails';
import ProductDocs from '../../components/document/ProductDocs';
import SpecialityLinks from '../../components/document/SpecialityLinks';
import AdditionalLinks from '../../components/document/AdditionalLinks';

import {
  addDocument,
  editDocument,
  getDocumentProducts,
  getDocumentDetails,
} from '../../redux/store/actions/documentActions';
import { getUsersList } from '../../redux/store/actions/userActions';
import { getProductsList } from '../../redux/store/actions/productActions';
import { getSpecialitiesList } from '../../redux/store/actions/specialityActions';

import { useTranslation, withTranslation, Trans } from 'react-i18next';
import {getGroupsList} from "../../redux/store/actions/groupActions";

const DocumentDetail = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const documentId = props.match.params.documentId;
  const documentDetail = useSelector((state) => state.document);
  const groupsList = useSelector((state) => state.group.groupsList);

  // const productsListFetched = useSelector((state)=> state.product.productsList.length ? true : false);
  // const specialitiesListFetched = useSelector((state)=> state.speciality.specialitiesList.length ? true : false);

  const [formState, setFormState] = useState({
    title: '',
    description: '',
    status: '',
    order: '',
    groups: '',
    showTitles: false,
  });
  const [fetchedDoc, setFetchedDoc] = useState(null);

  const onCancelButtonClick = () => {
    history.push('/');
  };

  const saveBasicInfo = (e) => {
    e.preventDefault();
    let data = {};
    let groups = [];
    formState.groups
      ? formState.groups.forEach((group) => {
          groups.push({ id: group.id });
        })
      : (groups = []);
    data.name = formState.title;
    data.description = formState.description;
    data.status = formState.status;
    data.order = formState.order;
    data.groups = JSON.stringify(groups);
    data.showTitles = formState.showTitles;
    data.showContactTitles = formState.showContactTitles;

    if (documentId === 'new') {
      dispatch(addDocument(data)).then((id) => {
        history.push(`/document-detail/${id}`);
      });
    } else {
      data.id = documentId;

      dispatch(editDocument(data));
    }
  };

  useEffect(() => {
    dispatch(getGroupsList());
  }, []);


  useEffect(() => {
    if (documentId !== 'new') {
      if (
        documentDetail.documentsList.filter((el) => el.id === documentId).length
      ) {
        setFetchedDoc(
          documentDetail.documentsList.filter((el) => el.id === documentId)[0]
        );
      } else {
        dispatch(getDocumentDetails(documentId));
      }
      dispatch(getProductsList());
      dispatch(getSpecialitiesList());
    }
  }, []);

  useEffect(() => {
    if (documentDetail.singleDoc) {
      setFetchedDoc(documentDetail.singleDoc);
    }
  }, [documentDetail.singleDoc]);

  useEffect(() => {
    if (
      !documentDetail.singleDoc &&
      fetchedDoc !== null &&
      documentId !== 'new'
    ) {
      setFetchedDoc(
        documentDetail.documentsList.filter((el) => el.id === documentId)[0]
      );
    }
  }, [
    JSON.stringify(
      documentDetail.documentsList.filter((el) => el.id === documentId)
    ),
  ]);

  useEffect(() => {
    if (fetchedDoc) {
      let groups = [];

      if (fetchedDoc.status !== 'pubblicato') {
        groups = [];
      } else {
        for (let i=0; i<fetchedDoc.groups.length; i++){
          groups.push(groupsList.find((el) => el.id === fetchedDoc.groups[i].id))
        }
      }

      setFormState({
        title: fetchedDoc.name,
        description: fetchedDoc.description,
        status: fetchedDoc.status,
        order: fetchedDoc.order,
        groups: groups,
        showTitles: fetchedDoc.showTitles,
      });
    }
  }, [fetchedDoc, JSON.stringify(groupsList)]);

  return (
    <div>
      <Breadcrumb
        title="Home"
        breadcrumbItem={t('Document Detail')}
        breadcrumbItemName={t('Document')}
      />
      <BasicDetails
        onCancelButtonClick={onCancelButtonClick}
        documentDetail={documentDetail}
        onSaveClick={saveBasicInfo}
        formState={formState}
        setFormState={setFormState}
        groupsList={groupsList}
      />
      {documentId !== 'new' && fetchedDoc && (
        <div className="mb-5">
          <ProductDocs
            products={fetchedDoc.associatedProducts}
            specialtiesLinks={fetchedDoc.specialtiesLinks}
            documentId={documentId}
          />
          <SpecialityLinks
            specialtiesLinks={fetchedDoc.specialtiesLinks}
            products={fetchedDoc.associatedProducts}
            documentId={documentId}
          />
          <AdditionalLinks
            additionalLinks={fetchedDoc.additionalLinks}
            documentId={documentId}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(DocumentDetail);
