import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';

import Login from '../views/Landing/Login';
import ForgotPassword from '../views/Landing/ForgotPassword';
import ResetPswSuccess from '../views/Landing/ResetPswSuccess';
import Home from '../views/Main/Home';
import Users from '../views/Main/Users';
import Products from '../views/Main/Products';
import Specialities from '../views/Main/Specialities';
import DocumentDetail from '../views/Main/DocumentDetail';
import Groups from '../views/Main/Groups';

import Header from '../components/common/Header';
import Loading from '../components/common/OverlaySpinner';

import request from '../helpers/requestHelper';
import AuthenticateHelper from '../helpers/authenticateHelper';

const Routes = (props) => {
  const authorization = AuthenticateHelper;
  const userDetails = useSelector((state) => state.user);
  const isLoading = useSelector(
    (state) =>
      state.user.isLoading ||
      state.group.isLoading ||
      state.product.isLoading ||
      state.speciality.isLoading ||
      state.document.isLoading
  );
  const docError = useSelector((state) => state.document.errorMsg);
  const userError = useSelector((state) => state.user.errorMsg);
  const groupError = useSelector((state) => state.group.errorMsg);
  const productError = useSelector((state) => state.product.errorMsg);
  const specialityError = useSelector((state) => state.speciality.errorMsg);
  const userDetails2 = useSelector((state) =>
    state.user.userDetails ? state.user.userDetails.user : null
  );

  const [userImg, setUserImg] = useState();
  const authRoutes = [
    { routePath: '/', comp: Home, header: true },
    { routePath: '/users', comp: Users, header: true },
    { routePath: '/products', comp: Products, header: true },
    { routePath: '/specialities', comp: Specialities, header: true },
    { routePath: '/groups', comp: Groups, header: true },
    {
      routePath: '/document-detail/:documentId',
      comp: DocumentDetail,
      header: true,
    },
  ];

  useEffect(() => {
    authorization();
  }, [userDetails]);

  useEffect(() => {
    if (
      (userError && userError.code === 401) ||
      (groupError && groupError.code === 401) ||
      (docError && docError.code === 401) ||
      (productError && productError.code === 401) ||
      (specialityError && specialityError.code === 401)
    ) {
      authorization();
    }
  }, [userError, groupError, docError, productError, specialityError]);

  useEffect(() => {
    if (userDetails2) {
      getPicture(userDetails2.avatar);
    } else if (localStorage.getItem('avatar')) {
      getPicture(localStorage.getItem('avatar'));
    }
  }, []);

  useEffect(() => {
    if (userDetails2) {
      getPicture(userDetails2.avatar);
    } else if (localStorage.getItem('avatar')) {
      getPicture(localStorage.getItem('avatar'));
    }
  }, [userDetails2]);

  const getPicture = async (avatarName) => {
    if (avatarName) {
      const res = await request({
        url: `files/${avatarName}/type/avatar`,
        auth: true,
        method: 'GET',
      });
      if (!res.code) {
        var objectURL = URL.createObjectURL(res);
        setUserImg(objectURL);
      }
    }
  };

  if (authorization()) {
    return (
      <div>
        <Loading isLoading={isLoading} />
        <Router>
          <Switch>
            {authRoutes.map((item, id) => {
              return (
                <Route key={id} exact path={item.routePath}>
                  <div>
                    <Header userImg={userImg} />
                    <Container>
                      <item.comp />
                    </Container>
                  </div>
                </Route>
              );
            })}

            <Redirect to="/" />
          </Switch>
        </Router>
      </div>
    );
  } else {
    return (
      <div>
        <Loading isLoading={isLoading} />
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>

            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>

            <Route exact path="/reset-password-complete">
              <ResetPswSuccess />
            </Route>

            <Redirect to="/" />
          </Switch>
        </Router>
      </div>
    );
  }
};

export default Routes;
