import {

	GET_DOCUMENTS_LIST_REQUEST,
	GET_DOCUMENTS_LIST_SUCCEED,
	GET_DOCUMENTS_LIST_FAILED,

	ADD_DOCUMENT_REQUEST,
	ADD_DOCUMENT_SUCCEED,
	ADD_DOCUMENT_FAILED,

	EDIT_DOCUMENT_REQUEST,
	EDIT_DOCUMENT_SUCCEED,
	EDIT_DOCUMENT_FAILED,

	DELETE_DOCUMENT_REQUEST,
	DELETE_DOCUMENT_SUCCEED,
	DELETE_DOCUMENT_FAILED,

	DUPLICATE_DOCUMENT_REQUEST,
	DUPLICATE_DOCUMENT_SUCCEED,
	DUPLICATE_DOCUMENT_FAILED,

	GET_DOCUMENT_DETAIL_REQUEST,
	GET_DOCUMENT_DETAIL_SUCCEED,
	GET_DOCUMENT_DETAIL_FAILED,

	ADD_PRODUCT_DOCUMENT_REQUEST,
	ADD_PRODUCT_DOCUMENT_FAILED,
	ADD_PRODUCT_DOCUMENT_SUCCEED,

	EDIT_PRODUCT_DOCUMENT_REQUEST,
	EDIT_PRODUCT_DOCUMENT_FAILED,
	EDIT_PRODUCT_DOCUMENT_SUCCEED,

	DELETE_PRODUCT_DOCUMENT_REQUEST,
	DELETE_PRODUCT_DOCUMENT_FAILED,
	DELETE_PRODUCT_DOCUMENT_SUCCEED,

	ADD_DOCUMENT_SPECIALITY_REQUEST,
	ADD_DOCUMENT_SPECIALITY_FAILED,
	ADD_DOCUMENT_SPECIALITY_SUCCEED,

	EDIT_DOCUMENT_SPECIALITY_REQUEST,
	EDIT_DOCUMENT_SPECIALITY_SUCCEED,
	EDIT_DOCUMENT_SPECIALITY_FAILED,

	DELETE_DOCUMENT_SPECIALITY_REQUEST,
	DELETE_DOCUMENT_SPECIALITY_FAILED,
	DELETE_DOCUMENT_SPECIALITY_SUCCEED,

	CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST,
	CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED,
	CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED,

	ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST,
	ADD_DOCUMENT_ADDITIONAL_LINK_FAILED,
	ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED,

	EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST,
	EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED,
	EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED,

	DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST,
	DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED,
	DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED,

	CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST,
	CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED,
	CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED,

} from '../../ActionTypes';


const initialState = {
	errorMsg: null,
	successMsg: null,
	documentsList: [],
	isLoading: false,
	documentProducts: [],
	subLoading: true,
	singleDoc: null,
	total: 0,
}


export default function userData(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {

		// GET_DOCUMENTS_LIST
		case GET_DOCUMENTS_LIST_REQUEST:
			return {
				...state,
				isLoading: false,
				errorMsg: null,
				successMsg: null,
				subLoading: true,
				documentProducts: [],
				singleDoc: null
			};
		case GET_DOCUMENTS_LIST_SUCCEED:
			return {
				...state,
				successMsg: 'Documents List Fetched',
				documentsList: payload.results && payload.results.length ? payload.results : [],
				total: payload.totalResults,
				subLoading: false
			};
		case GET_DOCUMENTS_LIST_FAILED:
			return {
				...state,
				documentsList: [],
				subLoading: false,
				errorMsg: payload,
				total: 0
			};

		// ADD_DOCUMENT
		case ADD_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case ADD_DOCUMENT_SUCCEED:
			return {
				...state,
				successMsg: 'Document Added',
				documentsList: state.total % 5 !== 0 ? state.documentsList.concat(payload) : state.documentsList,
				singleDoc: payload,
				isLoading: false,
				total: state.total + 1,
			};
		case ADD_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// EDIT_DOCUMENT
		case EDIT_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case EDIT_DOCUMENT_SUCCEED:
			let documents = state.documentsList;
			let documentIndex = state.documentsList.findIndex(item => item.id === payload.id);

			documents.splice(documentIndex, 1, { ...state.documentsList[documentIndex], ...payload })

			return {
				...state,
				successMsg: 'Document Edited',
				documentsList: documents,
				isLoading: false
			};
		case EDIT_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DELETE_DOCUMENT
		case DELETE_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case DELETE_DOCUMENT_SUCCEED:
			return {
				...state,
				successMsg: 'Document Deleted',
				documentsList: state.documentsList.filter((el) => el.id !== payload),
				isLoading: false,
				total: state.total - 1,
			};
		case DELETE_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DUPLICATE_DOCUMENT
		case DUPLICATE_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case DUPLICATE_DOCUMENT_SUCCEED:
			let duplDocList = state.documentsList;
			duplDocList.push(payload);

			return {
				...state,
				successMsg: 'Document Duplicated',
				duplicatedDocument: payload,
				documentsList: duplDocList,
				isLoading: false,
				total: state.total - 1,
			};
		case DUPLICATE_DOCUMENT_FAILED:
			return {
				...state,
				duplicatedDocument: undefined,
				isLoading: false,
				errorMsg: payload
			};

		// GET_DOCUMENT_DETAIL
		case GET_DOCUMENT_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case GET_DOCUMENT_DETAIL_SUCCEED:
			return {
				...state,
				successMsg: 'Document Details Fetched',
				singleDoc: payload,
				isLoading: false
			};
		case GET_DOCUMENT_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// ADD_PRODUCT_DOCUMENT
		case ADD_PRODUCT_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case ADD_PRODUCT_DOCUMENT_SUCCEED:
			let documents1 = state.documentsList;
			let documentIndex1 = state.documentsList.findIndex(item => item.id === payload.id);

			documents1.splice(documentIndex1, 1, { ...state.documentsList[documentIndex1], ...payload })

			return {
				...state,
				successMsg: 'Added Product Document',
				documentsList: documents1,
				singleDoc: payload,
				isLoading: false
			};
		case ADD_PRODUCT_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// EDIT_PRODUCT_DOCUMENT
		case EDIT_PRODUCT_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case EDIT_PRODUCT_DOCUMENT_SUCCEED:
			let documents11 = state.documentsList;
			let documentIndex11 = state.documentsList.findIndex(item => item.id === payload.id);

			documents11.splice(documentIndex11, 1, { ...state.documentsList[documentIndex11], ...payload })

			return {
				...state,
				successMsg: 'Prodcut Document Edited',
				documentsList: documents11,
				singleDoc: state.singleDoc ? payload : null,
				isLoading: false
			};

		case EDIT_PRODUCT_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DELETE_PRODUCT_DOCUMENT
		case DELETE_PRODUCT_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case DELETE_PRODUCT_DOCUMENT_SUCCEED:

			let documents2 = state.documentsList;
			let documentIndex2 = state.documentsList.findIndex(item => item.id === payload.docId);
			let changedOne = state.documentsList.filter((el) => el.id === payload.docId).length ? state.documentsList.filter((el) => el.id === payload.docId)[0] : state.singleDoc;

			changedOne.associatedProducts = changedOne.associatedProducts.filter((el) => el._id !== payload.id)
			documents2.splice(documentIndex2, 1, { ...state.documentsList[documentIndex2], ...changedOne })

			return {
				...state,
				successMsg: 'Product Document Deleted',
				documentsList: documents2,
				singleDoc: state.singleDoc ? changedOne : null,
				isLoading: false
			};
		case DELETE_PRODUCT_DOCUMENT_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// ADD_DOCUMENT_SPECIALITY
		case ADD_DOCUMENT_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case ADD_DOCUMENT_SPECIALITY_SUCCEED:
			let documents3 = state.documentsList;
			let documentIndex3 = state.documentsList.findIndex(item => item.id === payload.id);

			documents3.splice(documentIndex3, 1, { ...state.documentsList[documentIndex3], ...payload })

			return {
				...state,
				successMsg: 'Added Document Speciality',
				documentsList: documents3,
				singleDoc: payload,
				isLoading: false
			};
		case ADD_DOCUMENT_SPECIALITY_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DELETE_DOCUMENT_SPECIALITY
		case DELETE_DOCUMENT_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case DELETE_DOCUMENT_SPECIALITY_SUCCEED:

			let documents4 = state.documentsList;
			let documentIndex4 = state.documentsList.findIndex(item => item.id === payload.docId);
			let changedOne1 = state.documentsList.filter((el) => el.id === payload.docId).length ? state.documentsList.filter((el) => el.id === payload.docId)[0] : state.singleDoc;

			changedOne1.specialtiesLinks = changedOne1.specialtiesLinks.filter((el) => el._id !== payload.id)
			documents4.splice(documentIndex4, 1, { ...state.documentsList[documentIndex4], ...changedOne1 })

			return {
				...state,
				successMsg: 'Document Speciality Deleted',
				documentsList: documents4,
				singleDoc: state.singleDoc ? changedOne1 : null,
				isLoading: false
			};
		case DELETE_DOCUMENT_SPECIALITY_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// ADD_DOCUMENT_ADDITIONAL_LINK
		case ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED:
			let documents5 = state.documentsList;
			let documentIndex5 = state.documentsList.findIndex(item => item.id === payload.id);

			documents5.splice(documentIndex5, 1, { ...state.documentsList[documentIndex5], ...payload })

			return {
				...state,
				successMsg: 'Added Document Additional Link',
				documentsList: documents5,
				singleDoc: payload,
				isLoading: false
			};
		case ADD_DOCUMENT_ADDITIONAL_LINK_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DELETE_DOCUMENT_ADDITIONAL_LINK
		case DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED:

			let documents6 = state.documentsList;
			let documentIndex6 = state.documentsList.findIndex(item => item.id === payload.docId);
			let changedOne2 = state.documentsList.filter((el) => el.id === payload.docId).length ? state.documentsList.filter((el) => el.id === payload.docId)[0] : state.singleDoc;

			changedOne2.additionalLinks = changedOne2.additionalLinks.filter((el) => el._id !== payload.id)
			documents6.splice(documentIndex6, 1, { ...state.documentsList[documentIndex6], ...changedOne2 })

			return {
				...state,
				successMsg: 'Document Additional Link Deleted',
				documentsList: documents6,
				singleDoc: state.singleDoc ? changedOne2 : null,
				isLoading: false
			};
		case DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// EDIT_DOCUMENT_ADDITIONAL_LINK
		case EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED:
			let documents7 = state.documentsList;
			let documentIndex7 = state.documentsList.findIndex(item => item.id === payload.id);

			documents7.splice(documentIndex7, 1, { ...state.documentsList[documentIndex7], ...payload })

			return {
				...state,
				successMsg: 'Document Additional Link Edited',
				documentsList: documents7,
				singleDoc: state.singleDoc ? payload : null,
				isLoading: false
			};
		case EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};



		case EDIT_DOCUMENT_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading: true,
				errorMsg: null,
				successMsg: null,
			};
		case EDIT_DOCUMENT_SPECIALITY_SUCCEED:
			let documents8 = state.documentsList;
			let documentIndex8 = state.documentsList.findIndex(item => item.id === payload.id);

			documents8.splice(documentIndex8, 1, { ...state.documentsList[documentIndex8], ...payload })

			return {
				...state,
				successMsg: 'Document Speciality Link Edited',
				documentsList: documents8,
				singleDoc: state.singleDoc ? payload : null,
				isLoading: false
			};
		case EDIT_DOCUMENT_SPECIALITY_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// CHANGE_DOCUMENT_SPECIALITY_LIST
		case CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED:

			let documents9 = state.documentsList;
			let documentIndex9 = state.documentsList.findIndex(item => item.id === payload.id);

			documents9.splice(documentIndex9, 1, { ...state.documentsList[documentIndex9], ...payload })

			return {
				...state,
				successMsg: 'Document Speciality Link Order Changed',
				documentsList: documents9,
				singleDoc: state.singleDoc ? payload : null,
				isLoading: false
			};
		case CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST
		case CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED:

			let documents10 = state.documentsList;
			let documentIndex10 = state.documentsList.findIndex(item => item.id === payload.id);

			documents10.splice(documentIndex10, 1, { ...state.documentsList[documentIndex10], ...payload })

			return {
				...state,
				successMsg: 'Document Additional Link Order Changed',
				documentsList: documents10,
				singleDoc: state.singleDoc ? payload : null,
				isLoading: false
			};
		case CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				errorMsg: payload
			};

		// DEFAULT
		default:
			return state;
	}
}