import {
  
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCEED,
  GET_PRODUCTS_LIST_FAILED,

  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCEED,
  ADD_PRODUCT_FAILED,

  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCEED,
  EDIT_PRODUCT_FAILED,

  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCEED,
  DELETE_PRODUCT_FAILED,

} from '../../ActionTypes';


const initialState ={
	errorMsg: null,
	successMsg: null,
	productsList: [],
	isLoading: false,
	subLoading: true,
	total:0,
}


export default function userData(state = initialState, action){
	const {type, payload} = action;
	switch(type){
		
		case GET_PRODUCTS_LIST_REQUEST:
			return {
				...state,
				subLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case ADD_PRODUCT_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case EDIT_PRODUCT_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case DELETE_PRODUCT_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};


		case GET_PRODUCTS_LIST_SUCCEED:
			return{
				...state,
				successMsg: 'Product List Fetched',
				productsList: payload.results && payload.results.length ? payload.results : [],
				subLoading: false,
				total: payload.totalResults,
			};
		case GET_PRODUCTS_LIST_FAILED:
			return{
				...state,
				productsList: [],
				subLoading:false,
				errorMsg:payload,
				total:0
			};


		case ADD_PRODUCT_SUCCEED:
			return{
				...state,
				successMsg: 'Prodcut Added',
				productsList: state.total%5 !== 0 ? state.productsList.concat(payload) : state.productsList,
				isLoading: false,
				total:state.total +1,
			};
		case ADD_PRODUCT_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};


		case EDIT_PRODUCT_SUCCEED:
			let products = state.productsList;
      		let productIndex = state.productsList.findIndex(item => item.id === payload.id);
      		
      		products.splice(productIndex, 1, { ...state.productsList[productIndex], ...payload })

			return{
				...state,
				successMsg: 'Product Edited',
				productsList: products,
				isLoading: false
			};
		case EDIT_PRODUCT_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};


		case DELETE_PRODUCT_SUCCEED:
			return{
				...state,
				successMsg: 'Product Deleted',
				productsList: state.productsList.filter((el)=>el.id !== payload),
				isLoading: false,
				total:state.total -1,
			};
		case DELETE_PRODUCT_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};

		default:
			return state;
	}
}