import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../components/common/Breadcrumb';
import GroupsTable from '../../components/main/GroupsTable';
import SearchBar from '../../components/main/SearchBar';
import GroupModal from '../../components/main/GroupModal';
import DeleteModal from '../../components/main/DeleteModal';

import { getUsersList } from '../../redux/store/actions/userActions';

import {
  getGroupsList,
  editGroup,
  addGroup,
  deleteGroup,
} from '../../redux/store/actions/groupActions';


import { useTranslation } from 'react-i18next';

const Groups = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.group);
  const user = useSelector((state) => state.user);

  const [groupsData, setGroupsData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    leader: '',
    members: [],
    active: true,
  });
  const [deleteId, setDeleteId] = useState();
  const [groupSearch, setGroupSearch] = useState();

  const onNewButtonClick = () => {
    setIsModalOpen(true);
  };

  const onEditClick = async (item) => {
    setFormState({
      name: item.name,
      leader: item.leader,
      members: item.members,
      active: item.active,
      id: item.id,
    });
    setIsModalOpen(true);
  };

  const onSaveClick = (leader, members) => {
    setIsModalOpen(false);
    let data = {};

    data.name = formState.name;
    data.leader = leader;
    data.members = members;
    data.active = formState.active;

    if (formState.id) {
      data.id = formState.id;
      dispatch(editGroup(data));
    } else {
      dispatch(addGroup(data));
    }

    setFormState({
      name: '',
      leader: '',
      members: '',
      id: '',
      active: true,
    });
  };

  const onDeleteClick = (id) => {
    setDeleteId(id);
  };

  const handleDelete = () => {
    let id = deleteId;
    setDeleteId();

    dispatch(deleteGroup(id));
  };

  useEffect(() => {
    dispatch(getGroupsList());
  }, []);

  useEffect(() => {
    dispatch(getUsersList(1));
  }, []);

  useEffect(() => {
    setGroupsData(group.groupsList);
  }, [JSON.stringify(group.groupsList)]);

  useEffect(() => {
    setUsersData(user.usersList);
  }, [JSON.stringify(user.usersList)]);

  useEffect(() => {
    if (group.errorMsg && group.errorMsg.code === 401) history.go(0);
  }, [group.errorMsg]);

  return (
    <>
      <div>
        <Breadcrumb
          title="Home"
          breadcrumbItem={t('Groups')}
          breadcrumbItemName={t('Groups')}
        />
        <SearchBar
          name={t('Groups')}
          btnName="Group"
          onClick={onNewButtonClick}
          searchValue={groupSearch}
          setSearchValue={setGroupSearch}
        />
        <GroupsTable
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          groupsList={
            groupSearch
              ? groupsData.filter(
                  (el) =>
                    (el.name)
                      .toLowerCase()
                      .includes(groupSearch.toLowerCase())
                )
              : groupsData
          }
          total={group.total}
          loading={group.subLoading}
          getGroupsList={() => dispatch(getGroupsList())}
          usersList={usersData}
        />
        <GroupModal
          formState={formState}
          setFormState={setFormState}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          name="Group"
          groupsList={groupsData}
          usersList={usersData}
          onSaveClick={onSaveClick}
        />
        <DeleteModal
          isOpen={deleteId ? true : false}
          setIsOpen={setDeleteId}
          name="group"
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default Groups;
