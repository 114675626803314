import {
  
  GET_SPECIALITIES_LIST_REQUEST,
  GET_SPECIALITIES_LIST_SUCCEED,
  GET_SPECIALITIES_LIST_FAILED,

  ADD_SPECIALITY_REQUEST,
  ADD_SPECIALITY_SUCCEED,
  ADD_SPECIALITY_FAILED,

  EDIT_SPECIALITY_REQUEST,
  EDIT_SPECIALITY_SUCCEED,
  EDIT_SPECIALITY_FAILED,

  DELETE_SPECIALITY_REQUEST,
  DELETE_SPECIALITY_SUCCEED,
  DELETE_SPECIALITY_FAILED,


} from '../../ActionTypes';

import request from '../../../helpers/requestHelper';


export const getSpecialitiesList=(page)=> async dispatch=>{
	dispatch({type: GET_SPECIALITIES_LIST_REQUEST})

	try{
		const res = await request({
			url: page ? `specialties?page=${page}&limit=20` : `specialties?limit=999`,
			auth: true,
			method:'GET',
		});

		if(res.code){
			dispatch({
				type:GET_SPECIALITIES_LIST_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:GET_SPECIALITIES_LIST_SUCCEED,
				payload: res
			});	
		}
	} catch (err){
		dispatch({
			type:GET_SPECIALITIES_LIST_FAILED,
			payload: err
		});
	}
}


export const addSpeciality=(Data)=> async dispatch=>{
	dispatch({ type: ADD_SPECIALITY_REQUEST });


	var data = new FormData();

	data.append('name', Data.name);
	data.append('description', Data.description);
	data.append('active', Data.active);

	if(Data.logo)
		data.append('logo', Data.logo);


	try{
		const res = await request({
			url:`specialties`,
			auth: true,
			method:'POST',
			data: data
		});

		if(res.code){
			dispatch({
				type:ADD_SPECIALITY_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:ADD_SPECIALITY_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:ADD_SPECIALITY_FAILED,
			payload: err
		});
	}
}


export const editSpeciality=(Data)=> async dispatch=>{
	dispatch({ type: EDIT_SPECIALITY_REQUEST });


	var data = new FormData();

	data.append('name', Data.name);
	data.append('description', Data.description);
	data.append('logo', Data.logo);
	data.append('active', Data.active);

	try{
		const res = await request({
			url:`specialties/${Data.id}`,
			auth: true,
			method:'PATCH',
			data: data
		});

		if(res.code){
			dispatch({
				type:EDIT_SPECIALITY_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:EDIT_SPECIALITY_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:EDIT_SPECIALITY_FAILED,
			payload: err
		});
	}
}


export const deleteSpeciality=(id)=> async dispatch=>{
	dispatch({ type: DELETE_SPECIALITY_REQUEST });

	try{
		const res = await request({
			url:`specialties/${id}`,
			auth: true,
			method:'DELETE',
		});

		if(res.code){
			dispatch({
				type:DELETE_SPECIALITY_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:DELETE_SPECIALITY_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:DELETE_SPECIALITY_FAILED,
			payload: err
		});
	}
}