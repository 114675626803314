// User Actions
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCEED = 'USER_LOGIN_SUCCEED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGOUT_SUCCEED = 'USER_LOGOUT_SUCCEED';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_FAILED = 'USER_FORGOT_PASSWORD_FAILED';
export const USER_FORGOT_PASSWORD_SUCCEED = 'USER_FORGOT_PASSWORD_SUCCEED';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_FAILED = 'USER_RESET_PASSWORD_FAILED';
export const USER_RESET_PASSWORD_SUCCEED = 'USER_RESET_PASSWORD_SUCCEED';

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_FAILED = 'GET_USERS_LIST_FAILED';
export const GET_USERS_LIST_SUCCEED = 'GET_USERS_LIST_SUCCEED';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const ADD_USER_SUCCEED = 'ADD_USER_SUCCEED';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export const EDIT_USER_SUCCEED = 'EDIT_USER_SUCCEED';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED';

/* Groups Actions */

export const GET_GROUPS_LIST_REQUEST = 'GET_GROUPS_LIST_REQUEST';
export const GET_GROUPS_LIST_FAILED = 'GET_GROUPS_LIST_FAILED';
export const GET_GROUPS_LIST_SUCCEED = 'GET_GROUPS_LIST_SUCCEED';

export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';
export const ADD_GROUP_SUCCEED = 'ADD_GROUP_SUCCEED';

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_FAILED = 'EDIT_GROUP_FAILED';
export const EDIT_GROUP_SUCCEED = 'EDIT_GROUP_SUCCEED';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const DELETE_GROUP_SUCCEED = 'DELETE_GROUP_SUCCEED';

/* Common Actions*/
export const RESET_LOADING = 'RESET_LOADING';

/* Products Actions*/
export const GET_PRODUCTS_LIST_REQUEST = 'GET_PRODUCTS_LIST_REQUEST';
export const GET_PRODUCTS_LIST_FAILED = 'GET_PRODUCTS_LIST_FAILED';
export const GET_PRODUCTS_LIST_SUCCEED = 'GET_PRODUCTS_LIST_SUCCEED';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';
export const ADD_PRODUCT_SUCCEED = 'ADD_PRODUCT_SUCCEED';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED';
export const EDIT_PRODUCT_SUCCEED = 'EDIT_PRODUCT_SUCCEED';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';
export const DELETE_PRODUCT_SUCCEED = 'DELETE_PRODUCT_SUCCEED';

/* Specialities Actions*/
export const GET_SPECIALITIES_LIST_REQUEST = 'GET_SPECIALITIES_LIST_REQUEST';
export const GET_SPECIALITIES_LIST_FAILED = 'GET_SPECIALITIES_LIST_FAILED';
export const GET_SPECIALITIES_LIST_SUCCEED = 'GET_SPECIALITIES_LIST_SUCCEED';

export const ADD_SPECIALITY_REQUEST = 'ADD_SPECIALITY_REQUEST';
export const ADD_SPECIALITY_FAILED = 'ADD_SPECIALITY_FAILED';
export const ADD_SPECIALITY_SUCCEED = 'ADD_SPECIALITY_SUCCEED';

export const EDIT_SPECIALITY_REQUEST = 'EDIT_SPECIALITY_REQUEST';
export const EDIT_SPECIALITY_FAILED = 'EDIT_SPECIALITY_FAILED';
export const EDIT_SPECIALITY_SUCCEED = 'EDIT_SPECIALITY_SUCCEED';

export const DELETE_SPECIALITY_REQUEST = 'DELETE_SPECIALITY_REQUEST';
export const DELETE_SPECIALITY_FAILED = 'DELETE_SPECIALITY_FAILED';
export const DELETE_SPECIALITY_SUCCEED = 'DELETE_SPECIALITY_SUCCEED';

/* Documents Actions*/
export const GET_DOCUMENTS_LIST_REQUEST = 'GET_DOCUMENTS_LIST_REQUEST';
export const GET_DOCUMENTS_LIST_FAILED = 'GET_DOCUMENTS_LIST_FAILED';
export const GET_DOCUMENTS_LIST_SUCCEED = 'GET_DOCUMENTS_LIST_SUCCEED';

export const GET_DOCUMENT_DETAIL_REQUEST = 'GET_DOCUMENT_DETAIL_REQUEST';
export const GET_DOCUMENT_DETAIL_FAILED = 'GET_DOCUMENT_DETAIL_FAILED';
export const GET_DOCUMENT_DETAIL_SUCCEED = 'GET_DOCUMENT_DETAIL_SUCCEED';

export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST';
export const ADD_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED';
export const ADD_DOCUMENT_SUCCEED = 'ADD_DOCUMENT_SUCCEED';

export const EDIT_DOCUMENT_REQUEST = 'EDIT_DOCUMENT_REQUEST';
export const EDIT_DOCUMENT_FAILED = 'EDIT_DOCUMENT_FAILED';
export const EDIT_DOCUMENT_SUCCEED = 'EDIT_DOCUMENT_SUCCEED';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';
export const DELETE_DOCUMENT_SUCCEED = 'DELETE_DOCUMENT_SUCCEED';

export const DUPLICATE_DOCUMENT_REQUEST = 'DUPLICATE_DOCUMENT_REQUEST';
export const DUPLICATE_DOCUMENT_FAILED = 'DUPLICATE_DOCUMENT_FAILED';
export const DUPLICATE_DOCUMENT_SUCCEED = 'DUPLICATE_DOCUMENT_SUCCEED';

export const GET_DOCUMENT_PRODUCT_LIST_REQUEST =
  'GET_DOCUMENT_PRODUCT_LIST_REQUEST';
export const GET_DOCUMENT_PRODUCT_LIST_FAILED =
  'GET_DOCUMENT_PRODUCT_LIST_FAILED';
export const GET_DOCUMENT_PRODUCT_LIST_SUCCEED =
  'GET_DOCUMENT_PRODUCT_LIST_SUCCEED';

export const ADD_PRODUCT_DOCUMENT_REQUEST = 'ADD_PRODUCT_DOCUMENT_REQUEST';
export const ADD_PRODUCT_DOCUMENT_FAILED = 'ADD_PRODUCT_DOCUMENT_FAILED';
export const ADD_PRODUCT_DOCUMENT_SUCCEED = 'ADD_PRODUCT_DOCUMENT_SUCCEED';

export const EDIT_PRODUCT_DOCUMENT_REQUEST = 'EDIT_PRODUCT_DOCUMENT_REQUEST';
export const EDIT_PRODUCT_DOCUMENT_FAILED = 'EDIT_PRODUCT_DOCUMENT_FAILED';
export const EDIT_PRODUCT_DOCUMENT_SUCCEED = 'EDIT_PRODUCT_DOCUMENT_SUCCEED';

export const DELETE_PRODUCT_DOCUMENT_REQUEST =
  'DELETE_PRODUCT_DOCUMENT_REQUEST';
export const DELETE_PRODUCT_DOCUMENT_FAILED = 'DELETE_PRODUCT_DOCUMENT_FAILED';
export const DELETE_PRODUCT_DOCUMENT_SUCCEED =
  'DELETE_PRODUCT_DOCUMENT_SUCCEED';

export const GET_DOCUMENT_SPECIALITY_LIST_REQUEST =
  'GET_DOCUMENT_SPECIALITY_LIST_REQUEST';
export const GET_DOCUMENT_SPECIALITY_LIST_FAILED =
  'GET_DOCUMENT_SPECIALITY_LIST_FAILED';
export const GET_DOCUMENT_SPECIALITY_LIST_SUCCEED =
  'GET_DOCUMENT_SPECIALITY_LIST_SUCCEED';

export const ADD_DOCUMENT_SPECIALITY_REQUEST =
  'ADD_DOCUMENT_SPECIALITY_REQUEST';
export const ADD_DOCUMENT_SPECIALITY_FAILED = 'ADD_DOCUMENT_SPECIALITY_FAILED';
export const ADD_DOCUMENT_SPECIALITY_SUCCEED =
  'ADD_DOCUMENT_SPECIALITY_SUCCEED';

export const EDIT_DOCUMENT_SPECIALITY_REQUEST =
  'EDIT_DOCUMENT_SPECIALITY_REQUEST';
export const EDIT_DOCUMENT_SPECIALITY_FAILED =
  'EDIT_DOCUMENT_SPECIALITY_FAILED';
export const EDIT_DOCUMENT_SPECIALITY_SUCCEED =
  'EDIT_DOCUMENT_SPECIALITY_SUCCEED';

export const DELETE_DOCUMENT_SPECIALITY_REQUEST =
  'DELETE_DOCUMENT_SPECIALITY_REQUEST';
export const DELETE_DOCUMENT_SPECIALITY_FAILED =
  'DELETE_DOCUMENT_SPECIALITY_FAILED';
export const DELETE_DOCUMENT_SPECIALITY_SUCCEED =
  'DELETE_DOCUMENT_SPECIALITY_SUCCEED';

export const CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_REQUEST';
export const CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_FAILED';
export const CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED =
  'CHANGE_DOCUMENT_SPECIALITY_LIST_SUCCEED';

export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST';
export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED';
export const GET_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED =
  'GET_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED';

export const ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'ADD_DOCUMENT_ADDITIONAL_LINK_REQUEST';
export const ADD_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'ADD_DOCUMENT_ADDITIONAL_LINK_FAILED';
export const ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'ADD_DOCUMENT_ADDITIONAL_LINK_SUCCEED';

export const EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_REQUEST';
export const EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_FAILED';
export const EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'EDIT_DOCUMENT_ADDITIONAL_LINK_SUCCEED';

export const DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_REQUEST';
export const DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_FAILED';
export const DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED =
  'DELETE_DOCUMENT_ADDITIONAL_LINK_SUCCEED';

export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_REQUEST';
export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_FAILED';
export const CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED =
  'CHANGE_DOCUMENT_ADDITIONAL_LINK_LIST_SUCCEED';
