import React, { useState, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Input, Container, Button } from 'reactstrap'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const DuplicateModal=(props)=>{

	const { t, i18n } = useTranslation();

	const toggleModal=()=>{
		props.setIsOpen(!props.isOpen)
	}


	  return(
	      <Modal
	        isOpen={props.isOpen}
	        role="dialog"
	        autoFocus={true}
	        tabIndex="-1"
	        data-toggle="modal"
	        toggle={toggleModal}
	      >
	        <ModalHeader>{t('Document duplication')}</ModalHeader>
	        <ModalBody>
	        	<Container>
	        		<div className='font-size-14'>
	        			<div>{t('This operation will duplicate the selected document')}.</div>
	        			<div>{t('Are you sure?')}</div>
	        		</div>
	        		<div className='text-end'>
	        			<Button onClick={props.onDuplicate} color='primary' className='me-2'>{t('Duplicate')}</Button>
	        			<Button onClick={toggleModal} outline color='primary'>{t('Cancel')}</Button>
	        		</div>
	        	</Container>
	        </ModalBody>
	      </Modal>
	  )
}

export default DuplicateModal;
