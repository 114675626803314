import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../components/common/Breadcrumb';
import DocumentTable from '../../components/main/DocumentTable';
import SearchBar from '../../components/main/SearchBar';
import DeleteModal from '../../components/main/DeleteModal';

import {
  getDocumentsList,
  deleteDocument,
  duplicateDocument,
} from '../../redux/store/actions/documentActions';
import DuplicateModal from '../../components/main/DuplicateModal';

const Home = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.document);
  const duplicatedDocument = useSelector((state) => state.document);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [deleteId, setDeleteId] = useState();
  const [duplicateId, setDuplicateId] = useState();
  const [documentSearch, setDocumentSearch] = useState();

  useEffect(() => {
    dispatch(getDocumentsList(1));
  }, []);

  useEffect(() => {
    if (documents.errorMsg && documents.errorMsg.code === 401) history.go(0);
  }, [documents.errorMsg]);

  // GESTIONE ERRORE DUPLICAZIONE
  useEffect(() => {
    if (documents.errorMsg && documents.errorMsg.code === 404) alert(documents.errorMsg);
  }, [documents.errorMsg]);

  //Create new document
  const onNewButtonClick = () => {
    history.push('/document-detail/new');
  };

  //Edit document
  const onEditClick = (id) => {
    history.push(`/document-detail/${id}`);
  };

  // Duplicate document
  const onDuplicateClick = (id) => {
    setDuplicateId(id);
  }
  const handleDuplicate = () => {
    let id = duplicateId;
    setDuplicateId();

    dispatch(duplicateDocument(id));
  };

  // Delete document
  const onDeleteClick = (id) => {
    setDeleteId(id);
  };
  const handleDelete = () => {
    let id = deleteId;
    setDeleteId();

    dispatch(deleteDocument(id));
  };


  return (
    <div>
      <Breadcrumb
        title="Home"
        breadcrumbItem="Home"
        breadcrumbItemName="HOME PAGE"
      />
      <SearchBar
        name="Documents"
        btnName="Document"
        onClick={onNewButtonClick}
        searchValue={documentSearch}
        setSearchValue={setDocumentSearch}
      />
      <DocumentTable
        documentsList={
          documentSearch
            ? documents.documentsList.filter(
              (el) =>
                el.name
                  .toLowerCase()
                  .includes(documentSearch.toLowerCase()) ||
                el.description
                  .toLowerCase()
                  .includes(documentSearch.toLowerCase())
            )
            : documents.documentsList
        }
        total={documents.total}
        loading={documents.subLoading}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        onDuplicateClick={onDuplicateClick}
        getDocumentsList={(pageNo) => dispatch(getDocumentsList(pageNo))}
      />

      <DeleteModal
        isOpen={deleteId ? true : false}
        setIsOpen={setDeleteId}
        name="document"
        onDelete={handleDelete}
      />
      <DuplicateModal
        isOpen={duplicateId ? true : false}
        setIsOpen={setDuplicateId}
        name="document"
        onDuplicate={handleDuplicate}
      />
    </div>
  );
};

export default Home;
