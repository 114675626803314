import React, { useState, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Input, Container, Button } from 'reactstrap'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const DeleteModal=(props)=>{

	const { t, i18n } = useTranslation();

	const toggleModal=()=>{
		props.setIsOpen(!props.isOpen)
	}


	  return(
	      <Modal
	        isOpen={props.isOpen}
	        role="dialog"
	        autoFocus={true}
	        tabIndex="-1"
	        data-toggle="modal"
	        toggle={toggleModal}
	      >
	        <ModalHeader>{t('Action Confirmation')}</ModalHeader>
	        <ModalBody>
	        	<Container>
	        		<div className='font-size-14'>
	        			<div>{t('You are about to delete')} {props.name === 'user' || props.name === 'additional link' ? t('an') : t('a')} {t(props.name)}.</div>
	        			<div>{t('Are you sure?')}</div>
	        		</div>
	        		<div className='text-end'>
	        			<Button onClick={props.onDelete} color='primary' className='me-2'>{t('Yes')}</Button>
	        			<Button onClick={toggleModal} outline color='primary'>{t('No')}</Button>
	        		</div>
	        	</Container>
	        </ModalBody>
	      </Modal>
	  )
}

export default DeleteModal;
