import {
  GET_GROUPS_LIST_REQUEST,
  GET_GROUPS_LIST_SUCCEED,
  GET_GROUPS_LIST_FAILED,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCEED,
  ADD_GROUP_FAILED,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCEED,
  EDIT_GROUP_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCEED,
  DELETE_GROUP_FAILED,
} from '../../ActionTypes';

import request from '../../../helpers/requestHelper';

export const getGroupsList = () => async (dispatch) => {
  dispatch({ type: GET_GROUPS_LIST_REQUEST });

  try {
    const res = await request({
      url: `groups?role=mgr&limit=2000`,
      auth: true,
      method: 'GET',
    });

    if (res.code) {
      dispatch({
        type: GET_GROUPS_LIST_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: GET_GROUPS_LIST_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_GROUPS_LIST_FAILED,
      payload: err ? err : null,
    });
  }
};
export const addGroup = (Data) => async (dispatch) => {
  dispatch({ type: ADD_GROUP_REQUEST });

  let data = {
    name: Data.name,
    leader: Data.leader,
    members: Data.members,
    active: Data.active
  };

  try {
    const res = await request({
      url: `groups`,
      auth: true,
      method: 'POST',
      data: JSON.stringify(data),
      headers: {
        "Content-Type" : "application/json"
      }
    });
    if (res.code) {
      dispatch({
        type: ADD_GROUP_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: ADD_GROUP_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: ADD_GROUP_FAILED,
      payload: err,
    });
  }
};

export const editGroup = (Data) => async (dispatch) => {
  dispatch({ type: EDIT_GROUP_REQUEST });

  let data = {
    name: Data.name,
    leader: Data.leader,
    members: Data.members,
    active: Data.active
  };

  try {
    const res = await request({
      url: `groups/${Data.id}`,
      auth: true,
      method: 'PATCH',
      data: JSON.stringify(data),
      headers: {
        "Content-Type" : "application/json"
      }
    });

    if (res.code) {
      dispatch({
        type: EDIT_GROUP_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: EDIT_GROUP_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_GROUP_FAILED,
      payload: err,
    });
  }
};

export const deleteGroup = (id) => async (dispatch) => {
  dispatch({ type: DELETE_GROUP_REQUEST });

  try {
    const res = await request({
      url: `groups/${id}`,
      auth: true,
      method: 'DELETE',
    });

    if (res.code) {
      dispatch({
        type: DELETE_GROUP_FAILED,
        payload: res,
      });
    } else {
      dispatch({
        type: DELETE_GROUP_SUCCEED,
        payload: res,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_GROUP_FAILED,
      payload: err,
    });
  }
};
