import React, { useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Input, Container, Button } from 'reactstrap' 
import { FileDrop } from 'react-file-drop'

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const AdditionalLinkModal=(props)=>{

	const { t, i18n } = useTranslation();

	const fileInputRef = useRef(null);
	const fileInputRefImg = useRef(null);

	const onFileInputChange = (event) => {
		const { files } = event.target;
		props.setFormState({
			...props.formState,
			associatedDocument: files[0]
		})
	}

	const onFileInputChangeImg = (event) => {
		const { files } = event.target;
	  	props.setFormState({
			...props.formState,
			coverImage: files[0]
		})

		const fileReader = new FileReader()
        fileReader.onload = () => {
            props.setPreviewUrl(fileReader.result)
        }
        fileReader.readAsDataURL(files[0])
	  
	}

	const onTargetClick = () => {
		fileInputRef.current.click()
	}

	const onTargetClickImg = () => {
		fileInputRefImg.current.click()
	}

	const toggleModal=()=>{
		props.setIsOpen(!props.isOpen)
		props.setFormState({
			title:'',
			page:'',
			order:'',
			coverImage:null,
			associatedDocument:null,
			active: true,
		})
		props.setPreviewUrl()
	}

	const onFileDrop=(files, event) => {
		for (var i = 0; i < files.length; i++) {
			let exe = files[i].name.split('.').pop()
			if(exe === 'pdf'){
				props.setFormState({
					...props.formState,
					associatedDocument: files[i]
				})
				break;
			}
		}
	}

	const onFileDropImg=(files, event) => {
		for (var i = 0; i < files.length; i++) {
			let exe = files[i].name.split('.').pop()
			if(exe === 'jpg' || exe === 'jpeg' || exe === 'png'){
				props.setFormState({
					...props.formState,
					coverImage: files[i]
				})

				const fileReader = new FileReader()
		        fileReader.onload = () => {
		            props.setPreviewUrl(fileReader.result)
		        }
		        fileReader.readAsDataURL(files[i])
				break;
			}
		}
	}

	const onChangeHandler=(e)=>{
		const { name, value, type } = e.target;
		props.setFormState({
			...props.formState,
			[name]: type === 'checkbox' ? !props.formState.active : value
		})
	}

	return(
		<Modal
	        isOpen={props.isOpen}
	        role="dialog"
	        autoFocus={true}
	        centered={true}
	        tabIndex="-1"
	        data-toggle="modal"
	        toggle={toggleModal}
	      >
	        <ModalHeader>{t('Add / Edit Additional Link')}</ModalHeader>
	        <ModalBody>
	        	<Container>
	        		<form onSubmit={props.onSaveClick}>
		        		<div>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='productName'>{t('Link Title')} *</label></div>
			        		<div>
			        			<Input
				                  type='text'
				                  name='title'
				                  id='title'
				                  className='form-control' 
				                  value={props.formState.title}
				                  onChange={onChangeHandler}
				                  required 
				                /> 
			        		</div>
		        		</div>
		        		<div className='mt-3'>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='pdfDoc'>{t('PDF Document')} *</label></div>
			        		<div>
			        			<input
				                  type='file'
				                  name='associatedDocument'
				                  id='associatedDocument'
				                  accept='.pdf'
				                  ref={fileInputRef}
				                  onChange={onFileInputChange}
				                  style={{display:'none'}} 
				                /> 
				                {props.formState.associatedDocument ? 
				                	<FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >
					                	<div>
					                		<i className='bx bxs-file-pdf text-primary bx-lg'></i>
					                		<div>{props.formState.associatedDocument.name ? props.formState.associatedDocument.name : props.formState.associatedDocument}</div>
					                	</div>
					                </FileDrop>
					            :
					                <FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >	
					                	<div>
					                		<div className='text-center'><UploadIcon/></div>
					                		<div>{t('Drag a file in this area or select it from your device')}</div>
					                	</div>
					                </FileDrop>
					            }
			        		</div>
		        		</div>
		        		<div className='mt-3'>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='coverImage'>{t('Cover Image')} *</label></div>
			        		<div>
			        			<input
				                  type='file'
				                  name='coverImage'
				                  id='coverImage'
				                  accept='.jpg, .jpeg, .png'
				                  ref={fileInputRefImg}
				                  onChange={onFileInputChangeImg}
				                  style={{display:'none'}} 
				                /> 
				                {(props.formState.coverImage && props.previewUrl) ? 
					                <FileDrop 
					                	onTargetClick={onTargetClickImg}
					                	onDrop={onFileDropImg}
					                >
					                	<div>
					                		<img style={{maxHeight:'90px'}} src={props.previewUrl} />
					                	</div>
					                </FileDrop>
					        	
					        	:

					        	props.formState.coverImage ? 
				                	<FileDrop 
					                	onTargetClick={onTargetClickImg}
					                	onDrop={onFileDropImg}
					                >
					                	<div>
					                		<i className='bx bxs-image text-primary bx-lg'></i>
					                		<div>{props.formState.coverImage.name ? props.formState.coverImage.name : props.formState.coverImage}</div>
					                	</div>
					                </FileDrop>
					            :
					                <FileDrop 
					                	onTargetClick={onTargetClickImg}
					                	onDrop={onFileDropImg}
					                >	
					                	<div>
					                		<div className='text-center'><UploadIcon/></div>
					                		<div>{t('Drag a file in this area or select it from your device')}</div>
					                	</div>
					                </FileDrop>
					            }
			        		</div>
		        		</div>
	        			<div className='mt-3 col-md-6'>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='page'>{t('Page')} # *</label></div>
			        		<div>
			        			<Input
				                  type='telno'
				                  name='page'
				                  id='page'
				                  className='form-control' 
				                  value={props.formState.page}
				                  onChange={onChangeHandler}
				                  required 
				                />
			        		</div>
		        		</div>
		        		<div className="mt-3 form-check">
			                <Input
			                  type="checkbox"
			                  className="rounded-check"
			                  id="active"
			                  name='active'
			                  style={{width:'1.22em', height:'1.2em'}}
			                  checked={props.formState.active}
		                  	  onChange={onChangeHandler}
			                />
			                <label className="form-check-label ms-2" style={{marginTop:1}} htmlFor="active">
								{t('Visible inside the document')}
			                </label>
			            </div>
		        		<Row>
				            <div className='text-end mt-4'>
				            	<Button disabled={(!props.formState.associatedDocument || !props.formState.coverImage) ? true : false} type='submit' className='btn-sm me-2' color='primary'>{t('Save Link')}</Button>
				            	<Button className='btn-sm' type='button' onClick={toggleModal}>{t('Cancel')}</Button>
				            </div>
			            </Row>
			        </form>
	        	</Container>
	        </ModalBody>
	    </Modal>
	)
}


export default AdditionalLinkModal;