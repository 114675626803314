import React, { useState, useEffect } from 'react';
import { Container, Row, Badge } from 'reactstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import moment from 'moment';
import 'moment/locale/it';

import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

moment.locale('it');

const DocumentTable = (props) => {
  const isLoading = props.loading === true ? true : false;
  const [pageNo, setPageNo] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (Math.ceil(props.total / 5) < pageNo) {
      setPageNo(Math.ceil(props.total / 5));
      props.getDocumentsList(Math.ceil(props.total / 5));
    }
  }, [props.total]);

  const capitalizeFirstLetter = (string) => {
    const str1 = string.split(' ')[0];
    const str2 = string.split(' ')[1];
    const str3 = string.split(' ')[2];

    return (
      str1 + ' ' + str2.charAt(0).toUpperCase() + str2.slice(1) + ' ' + str3
    );
  };

  const columns = [
    {
      key: 'doc-row',
      text: '#',
      dataField: 'doc-row',
      headerStyle: { textAlign: 'center' },
      formatter: (rowContent, row) => {
        return (
          <div className="text-center links">
            <DocumentIcon />
          </div>
        );
      },
    },
    {
      key: 'document',
      dataField: 'document',
      text: t('Document'),
      formatter: (rowContent, row) => {
        return (
          <Row>
            <div className="fw-bold">{row.name}</div>
            <div>{row.description}</div>
          </Row>
        );
      },
    },
    {
      key: 'updated_at',
      dataField: 'updated_at',
      text: t('Last Edit'),
      formatter: (rowContent, row) => {
        return capitalizeFirstLetter(
          moment(row.updatedAt).format('DD MMM, YYYY')
        );
      },
    },
    {
      key: 'order',
      dataField: 'order',
      text: t('Order'),
    },
    {
      key: 'status',
      dataField: 'status',
      text: t('Status'),
      formatter: (rowContent, row) => {
        return (
          <Badge
            className={
              'font-size-12 badge-soft-' +
              (row.status.toLowerCase() === 'bozza'
                ? 'draft'
                : row.status.toLowerCase() === 'pubblicato'
                ? 'published'
                : 'archieved')
            }
            pill
            color={
              row.status.toLowerCase() === 'bozza'
                ? 'draft'
                : row.status.toLowerCase() === 'pubblicato'
                ? 'published'
                : 'archieved'
            }
          >
            {row.status.toLowerCase() === 'bozza'
              ? t('Draft')
              : row.status.toLowerCase() === 'pubblicato'
              ? t('Published')
              : t('Archieved')}
          </Badge>
        );
      },
    },
    {
      key: 'actions',
      dataField: 'actions',
      text: t('Actions'),
      formatter: (rowContent, row) => {
        return (
          <div className="text-primary">
            <span>
            <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip>
                    {t('Edit')}
                  </Tooltip>
                }
              >
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onEditClick(row.id)}
                className="bx bx-file bx-sm"
              ></i>
              </OverlayTrigger>
            </span>

            <span>
              <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip>
                    {t('Duplicate')}
                  </Tooltip>
                }
              >
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onDuplicateClick(row.id)}
                className="bx bx-copy-alt bx-sm px-2"
              ></i>
              </OverlayTrigger>
            </span>

            <span>
              <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                  <Tooltip>
                    {t('Delete')}
                  </Tooltip>
                }
              >
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onDeleteClick(row.id)}
                className="bx bx-trash bx-sm"
              ></i>
              </OverlayTrigger>
            </span>
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    sizePerPage: 5,
    page: pageNo ? pageNo : 1,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    totalSize: props.total,
  });

  const onTableChange = (name, e) => {
    setPageNo(e.page);
    props.getDocumentsList(e.page);
  };

  return (
    <Container fluid={true} className="table-container">
      <BootstrapTable
        keyField="id"
        data={props.documentsList}
        columns={columns}
        pagination={pagination}
        bordered={false}
        onTableChange={onTableChange}
        // remote
        loading={isLoading}
        noDataIndication={<></>}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            spinner: (base) => ({
              ...base,
              '& svg circle': { stroke: '#DA1021' },
              width: '50px',
            }),
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.9)',
            }),
          },
        })}
        classes="table-responsive table-common"
      />
    </Container>
  );
};

export default DocumentTable;
