import {
  GET_GROUPS_LIST_REQUEST,
  GET_GROUPS_LIST_SUCCEED,
  GET_GROUPS_LIST_FAILED,
  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCEED,
  ADD_GROUP_FAILED,
  EDIT_GROUP_REQUEST,
  EDIT_GROUP_SUCCEED,
  EDIT_GROUP_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCEED,
  DELETE_GROUP_FAILED,
  RESET_LOADING,
} from '../../ActionTypes';

const initialState = {
  errorMsg: null,
  successMsg: null,
  groupDetails: null,
  groupsList: [],
  isLoading: false,
  subLoading: true,
  total: 0,
};

export default function groupData(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case GET_GROUPS_LIST_REQUEST:
      return {
        ...state,
        subLoading: true,
        errorMsg: null,
        successMsg: null,
      };
    case ADD_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: null,
        successMsg: null,
      };
    case EDIT_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMsg: null,
        successMsg: null,
      };
    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        isLoading:true,
        errorMsg:null,
        successMsg: null,
      };

    case GET_GROUPS_LIST_SUCCEED:
      return {
        ...state,
        successMsg: 'Group List Fetched',
        groupsList:
          payload.results && payload.results.length ? payload.results : [],
        subLoading: false,
        total: payload.totalResults,
      };
    case GET_GROUPS_LIST_FAILED:
      return {
        ...state,
        groupsList: [],
        subLoading: false,
        errorMsg: payload,
        total: 0,
      };
    case ADD_GROUP_SUCCEED:
      return {
        ...state,
        successMsg: 'Group Added',
        groupsList:
          state.total % 5 !== 0
            ? state.groupsList.concat(payload)
            : state.groupsList,
        isLoading: false,
        total: state.total + 1,
      };
    case ADD_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
      };

    case EDIT_GROUP_SUCCEED:
      let groups = state.groupsList;
      let groupIndex = state.groupsList.findIndex(
        (item) => item.id === payload.id
      );

      groups.splice(groupIndex, 1, {
        ...state.groupsList[groupIndex],
        ...payload,
      });

      return {
        ...state,
        successMsg: 'Group Edited',
        groupsList: groups,
        isLoading: false,
      };
    case EDIT_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
      };

    case DELETE_GROUP_SUCCEED:
      return {
        ...state,
        successMsg: 'Group Deleted',
        groupsList: state.groupsList.filter((el) => el.id !== payload),
        isLoading: false,
        total: state.total - 1,
      };
    case DELETE_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
}
