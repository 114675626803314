import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Breadcrumb from '../../components/common/Breadcrumb'
import ProductsTable from '../../components/main/ProductsTable'
import SearchBar from '../../components/main/SearchBar'
import ProductSpecialityModal from '../../components/main/ProductSpecialityModal'
import DeleteModal from '../../components/main/DeleteModal'

import { getProductsList, addProduct, editProduct, deleteProduct } from '../../redux/store/actions/productActions'

import request from '../../helpers/requestHelper';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Products=(props)=>{

	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();
	const history = useHistory()
  	const product = useSelector((state)=> state.product);

  	const [productsData, setProductsData] = useState([])
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formState, setFormState] = useState({
		name:'',
		description:'',
		logo:'',
		active:true
	})
	const [deleteId, setDeleteId] = useState()
	const [productSearch, setProductSearch] = useState()
	const [productPics, setProductPics] = useState([])
	const [previewUrl, setPreviewUrl] = useState();

	useEffect(()=>{
		dispatch(getProductsList(1))
	},[])

	useEffect(()=>{
		setProductsData(product.productsList);
		if(product.productsList.length){
			getPicture()
		}
	},[JSON.stringify(product.productsList)])

	useEffect(()=>{
		if(product.errorMsg && product.errorMsg.code === 401)
			history.go(0);
	},[product.errorMsg])

	const getPicture= async ()=>{

		let oldData = JSON.parse(JSON.stringify(product.productsList));

		for(var i = 0; i < product.productsList.length; i++){
			if(product.productsList[i].logo){
				const res = await request({
					url:`files/${product.productsList[i].logo}/type/products`,
					auth: true,
					method:'GET',
				});
				if(!res.code){
					var objectURL = URL.createObjectURL(res);
					oldData[i].img = objectURL;
				}					
			}
		}
		setProductsData(oldData)
	}


	const onNewButtonClick=()=>{
		if(!formState.active){
			setFormState({
				...formState,
				active:true
			})
		}
		setIsModalOpen(true);
	}

	const onEditClick=(item)=>{
		setFormState({
			name: item.name,
			description: item.description,
			active: item.active,
			id: item.id,
			logo: item.logo,
		})
		getSinglePicture(item.logo)
		setIsModalOpen(true)
	}

	const onSaveClick=(e)=>{
		setIsModalOpen(false)
		e.preventDefault();

	    let data = {};

	    data.name = formState.name;
	    //data.description = formState.description;
	    data.logo = formState.logo;
	    data.active = formState.active;

		if(formState.description !== '') {

			data.description = formState.description;

		}

	    if(formState.id){
	    	data.id = formState.id;
	    	dispatch(editProduct(data));
	    }
	    else
	    	dispatch(addProduct(data));

	    setFormState({})
	}

	const onDeleteClick=(id)=>{
		setDeleteId(id)
	}

	const handleDelete=()=>{
		let id = deleteId;
		setDeleteId()

		dispatch(deleteProduct(id))
	}

	const getSinglePicture= async (logoName)=>{

		if(logoName){
			const res = await request({
				url:`files/${logoName}/type/products`,
				auth: true,
				method:'GET',
			});
			if(!res.code){
				var objectURL = URL.createObjectURL(res);
				setPreviewUrl(objectURL)
			}					
		}
	}

	return(
		<div>
			<Breadcrumb title='Home' breadcrumbItem={t('Products')} breadcrumbItemName={t('Products')}/>
			<SearchBar 
				name={t('Products')}
				btnName='Product' 
				onClick={onNewButtonClick}
				searchValue={productSearch}
				setSearchValue={setProductSearch}
			/>
			<ProductsTable 
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
				productsList={productSearch ? productsData.filter((el) => el.name.toLowerCase().includes(productSearch.toLowerCase()) || el.description.toLowerCase().includes(productSearch.toLowerCase())) : productsData}
				total={product.total}
				loading={product.subLoading}
				getProductsList={(pageNo)=> dispatch(getProductsList(pageNo))}
			/>
			<ProductSpecialityModal 
				formState={formState}
				setFormState={setFormState}
				isOpen={isModalOpen} 
				setIsOpen={setIsModalOpen} 
				name='Product'
				onSaveClick={onSaveClick}
				previewUrl={previewUrl}
				setPreviewUrl={setPreviewUrl}
			/>
			<DeleteModal
				isOpen={deleteId ? true : false} 
				setIsOpen={setDeleteId}
				name='product'
				onDelete={handleDelete}
			/>
		</div>
	)
}

export default Products;