import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Input} from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '../../components/common/OverlaySpinner'

import BackgroundImg from '../../components/landing/BackgroundImg'
import Logo from '../../assets/images/logo.png'

import { userLogin } from '../../redux/store/actions/userActions'
import { resetLoading } from '../../redux/store/actions/commonActions'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Login = (props)=>{

  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state)=> state.user);

  const [formState, setFormState] = useState({
    email:'',
    password:'',
    fromweb:1,
    remember:false,
  })

  useEffect(()=>{
    dispatch(resetLoading())
  },[])

  const onChangeHandler=(e)=>{
    const { name, value, type } = e.target;
    setFormState({
      ...formState,
      [name] : type === 'checkbox' ? !formState.remember : value
    })
  }

  const handleLogin=(e)=>{
    e.preventDefault();

    let data = {};

    data.password = formState.password;
    data.email = formState.email;
    data.fromweb = formState.fromweb;

    dispatch(userLogin(data, formState.remember))
      .then((data)=>{
        if(data && data.tokens && data.tokens.access && data.tokens.access.token){
            history.push('/')
        }
      })
  }

  const { t, i18n } = useTranslation();

  return(
    <>
    <Loading isLoading={userDetails.isLoading}/>
    <div className='container'>
      <BackgroundImg/>
      
      <Row style={{position:'absolute',left:0,margin:0, width:'100%'}} className=' white-sq-container ps-5 pe-5 ps-md-0 pe-md-0'>
        <Col xl={4} md={6} className='mx-auto bg-white p-5 pt-4'>
          <Col className='ps-1 ps-md-5 pe-1 pe-md-5 pt-2 pt-md-4 pb-2 pb-md-4'>
            <Row><Col className='text-center ps-5 pe-5'><img src={Logo} alt='Logo' className='mw-100'/></Col></Row>
            <form onSubmit={handleLogin}>
              <Row className='mt-4'>
                <Input
                    type='text'
                    name='email'
                    id='email'
                    className='form-control form-control-login'   
                    placeholder={t('Username')}
                    value={formState.email}
                    onChange={onChangeHandler}     
                    required              
                  /> 
              </Row>
              <Row className='mt-2'>
                <Input
                    type='password'
                    name='password'
                    id='password'
                    className='form-control form-control-login'   
                    placeholder={t('Password')}  
                    value={formState.password}
                    onChange={onChangeHandler}   
                    required                 
                  /> 
              </Row>
              <Row className='mt-3 pb-2'>
                <Col className="form-check text-start" style={{fontWeight:500}}>
                  <Input
                    type="checkbox"
                    className="rounded-check"
                    id="remember"
                    name='remember'
                    style={{borderRadius:50, width:'1.22em', height:'1.2em',padding:7}}
                    checked={formState.remember}
                    onChange={onChangeHandler}
                  />
                  <label className="form-check-label ms-1" style={{marginTop:1}} htmlFor="remember">
                    {t('Remember Me')}
                  </label>
                </Col>
                <Col className='ml-auto text-end text-primary p-0 d-none' style={{fontWeight:500}}><Link to='/reset-password'>{t('Lost Password?')}</Link></Col>
              </Row>
              <Input
                    type="hidden"
                    id="fromweb"
                    name='fromweb'
                    value='1'
                  />
              <Row className='mt-4'><Button type='submit' color='primary'>{t('Login')}</Button></Row>
            </form>
          </Col>
        </Col>
      </Row>
    
    </div>
    </>
  )
}

export default Login;