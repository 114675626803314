import React, { useState, useEffect } from 'react';
import { Container, Input } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import proPic from './../../assets/images/propic.png';

import { users } from '../../constants/Data';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const GroupsTable = (props) => {
  const { t, i18n } = useTranslation();

  const isLoading = props.loading === true ? true : false;
  const [pageNo, setPageNo] = useState(null);

  const columns = [
    {
      key: 'doc-row',
      text: '#',
      dataField: 'doc-row',
      formatter: (rowContent, row) => {
        return (
          <div className="user-avatar-div">
            <div>
              {row.name[0].toUpperCase()}
            </div>
          </div>
        );
      },
    },
    {
      key: 'name',
      dataField: 'name',
      text: t('Group Name'),
      style: { fontWeight: 500 },
    },

    {
      key: 'leader',
      dataField: 'leader',
      text: t('Group Leader'),
      style: { color: '#74788D' },
      formatter: (rowContent, row) =>
        props.usersList.find((el) => el.id === row.leader)?.name + " " + props.usersList.find((el) => el.id === row.leader)?.surname,
    },

    {
      key: 'actions',
      dataField: 'actions',
      text: t('Actions'),
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: (rowContent, row) => {
        return (
          <div className="text-primary">
            <span>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onEditClick(row)}
                className="bx bx-user-circle bx-sm"
              ></i>
            </span>
            <span>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onDeleteClick(row.id)}
                className="bx bx-trash bx-sm"
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    sizePerPage: 10,
    page: pageNo ? pageNo : 1,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    totalSize: props.total,
  });

  const onTableChange = (name, e) => {
    setPageNo(e.page);
    props.getGroupsList(e.page);
  };

  useEffect(() => {
    if (Math.ceil(props.total / 5) < pageNo) {
      setPageNo(Math.ceil(props.total / 5));
      props.getGroupsList(Math.ceil(props.total / 5));
    }
  }, [props.total]);

  return (
    <Container fluid={true} className="table-container">
      <BootstrapTable
        keyField="id"
        data={props.groupsList}
        columns={columns}
        pagination={pagination}
        bordered={false}
        classes="table-responsive table-common user-table"
        headerClasses="header-class-dark-table"
        overlay={overlayFactory({
          spinner: true,
          styles: {
            spinner: (base) => ({
              ...base,
              '& svg circle': { stroke: '#DA1021' },
              width: '50px',
            }),
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.9)',
            }),
          },
        })}
        onTableChange={onTableChange}
        // remote
        loading={isLoading}
        noDataIndication={<></>}
      />
    </Container>
  );
};

export default GroupsTable;
