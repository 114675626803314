import React, { useState, useEffect } from 'react'
import { Container, Row } from 'reactstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux'

import SpecialityLinkModal from './SpecialityLinkModal'
import DeleteModal from '../main/DeleteModal'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg' 

import request from '../../helpers/requestHelper';

import { addDocumentSpeciality, deleteDocumentSpeciality, editDocumentSpeciality, updateDocumentSpecialityOrder } from '../../redux/store/actions/documentActions'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const SpecialityLinks=(props)=>{

	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();
	const productsList = useSelector((state)=> state.product.productsList);
  	const specialitiesList = useSelector((state)=> state.speciality.specialitiesList);
	
	const [Data, setData] = useState([])
	const [deleteId, setDeleteId] = useState()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formState, setFormState] = useState({
		product:'',
		specialty:'',
		contact:'',
		page:'',
		order:'',
		coverImage:null,
		active: true,
	});
	const [previewUrl, setPreviewUrl] = useState();

	useEffect(()=>{
		setData(props.specialtiesLinks.sort((a,b)=> {return a.order - b.order}))
	},[JSON.stringify(props.specialtiesLinks)])

	const getSinglePicture= async (imgName)=>{

		if(imgName){
			const res = await request({
				url:`files/${props.documentId}/file/${imgName}`,
				auth: true,
				method:'GET',
			});
			if(!res.code){
				var objectURL = URL.createObjectURL(res);
				setPreviewUrl(objectURL)
			}					
		}
	}

	const saveSpeciality=(e)=>{
		e.preventDefault();
		setIsModalOpen(false);

		let data = {};

		data.docId = props.documentId;
	    data.product = formState.product;
	    data.specialty = formState.specialty;
	    data.contact = formState.contact;
	    data.page = formState.page;
	    data.active = formState.active;
	    data.order = formState.order ? formState.order : props.specialtiesLinks.length + 1;

	    if(formState.coverImage && formState.coverImage.name)
	    	data.coverImage = formState.coverImage;

	    if(formState.id){
	    	data.id = formState.id;
	    	dispatch(editDocumentSpeciality(data))
	    }
	    else
	    	dispatch(addDocumentSpeciality(data))

	   	setFormState({
			product:'',
			specialty:'',
			contact:'',
			page:'',
			order:'',
			coverImage:null,
			active: true,
		})

	}

	const handleDelete=()=>{
		let id = deleteId;
		setDeleteId()

		dispatch(deleteDocumentSpeciality(props.documentId, id))
	}

	const handleEdit=(item)=>{
		setFormState({
			product: item.product,
			specialty: item.specialty,
			contact: item.contact,
			page: item.page,
			coverImage: item.coverImageFileName,
			id: item._id,
			order: item.order,
			active: item.active,
		})
		getSinglePicture(item.coverImage)
		setIsModalOpen(true)
	}

	const onDragEnd=(e)=>{
		const { destination, source, draggableId } = e;

	    if (!destination) {return;}
	    else if (destination.droppableId === source.droppableId && destination.index === source.index) {return;}
		else{
			const newData = JSON.parse(JSON.stringify(Data));
			const element = Data[Number(draggableId)]
			newData.splice(source.index, 1);
			newData.splice(destination.index, 0, element);
			setData(newData)
			let order = {};
			for(var i = 0; i < newData.length; i++){
				order[i] = newData[i]._id;
			}
			dispatch(updateDocumentSpecialityOrder(props.documentId ,order))
		}
	}

	const getItemStyle = (isDragging, draggableStyle) => ({
	  display: isDragging ? "table" : "",
	  ...draggableStyle
	});

	return(
		<>
			<Container fluid={true} className='p-3 dark-bg'>
				<Row><div className='fw-bold'>{t('Specialities Links')} <span onClick={()=>setIsModalOpen(true)} className='ms-2 clickable'><PlusIcon/></span></div></Row>
				{Data.length ?
					<Row className='mt-3'>
						<div className='table-container-speciality'>
							<DragDropContext onDragEnd={onDragEnd}>
								<table style={{width:'100%'}} className='document-edit-table'>
									<thead>
										<tr>
											<th width='5%'>{t('Order')}</th>
											<th width='20%'>{t('Speciality')}</th>
											<th width='20%'>{t('Product')}</th>
											<th width='10%'>{t('Contact')} #</th>
											<th width='20%'>{t('Cover Image')}</th>
											<th width='10%'>{t('Page')} #</th>
											<th width='10%'>{t('Actions')}</th>
										</tr>
									</thead>
									<Droppable droppableId="table-1">
										{(provided, snapshot) => (
											<tbody ref={provided.innerRef} {...provided.droppableProps}>
												{Data.map((item, id)=>{
													return(
														<Draggable draggableId={id.toString()} index={id} key={id}>
															{(provided, snapshot) => (
																<tr 
																	ref={provided.innerRef}
																	{...provided.draggableProps}
										                            key={id}
										                            style={getItemStyle(
											                          snapshot.isDragging,
											                          provided.draggableProps.style
											                        )}
																>
																	<td><div className='text-primary'><i {...provided.dragHandleProps} style={{border:'1px solid #da1021', borderRadius:5}} className='bx bx-menu clickable'></i></div></td>
																	<td><div className='form-control'>{specialitiesList.find((el)=> el.id === item.specialty) ? specialitiesList.find((el)=> el.id === item.specialty).name : item.specialty}</div></td>
																	<td><div className='form-control'>{productsList.find((el)=> el.id === item.product) ? productsList.find((el)=> el.id === item.product).name : item.product}</div></td>
																	<td><div className='form-control'>{item.contact}</div></td>
																	<td><div className='form-control' style={{textOverflow:'ellipsis', whiteSpace: 'nowrap', overflow:'hidden'}}>{item.coverImageFileName}</div></td>
																	<td><div className='form-control'>{item.page}</div></td>
																	<td>
																		<div className='text-primary'>
													        				<span><i onClick={()=> handleEdit(item)} className='bx bx-file clickable'></i></span>
													        				<span><i onClick={()=> setDeleteId(item._id)} className='bx bx-trash clickable'></i></span>
													        			</div>
																	</td>
																</tr>
															)}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</tbody>
										)}
									</Droppable>
								</table>
							</DragDropContext>
						</div>
					</Row>
				:''}
			</Container>
			<SpecialityLinkModal 
				isOpen={isModalOpen} 
				setIsOpen={setIsModalOpen}
				formState={formState}
				setFormState={setFormState}
				onSaveClick={saveSpeciality}
				products={props.products}
				previewUrl={previewUrl}
				setPreviewUrl={setPreviewUrl}
			/>
			<DeleteModal
				isOpen={deleteId ? true : false} 
				setIsOpen={setDeleteId}
				name='specialty link'
				onDelete={handleDelete}
			/>
		</>
	)
}

export default SpecialityLinks