import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as ProductsIcon } from '../../assets/icons/products.svg';
import { ReactComponent as SpecialitiesIcon } from '../../assets/icons/specialities.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/user.svg';
import { ReactComponent as GroupsIcon } from '../../assets/icons/users.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';

import Logo from '../../assets/images/logo.png';
import tempStaticAvatar from './../../assets/images/sample-avatar.png';

import { userLogout } from '../../redux/store/actions/userActions';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userToken = useSelector((state) =>
    state.user.userDetails && state.user.userDetails.tokens
      ? state.user.userDetails.tokens.refresh.token
      : ''
  );
  const userDetails = useSelector((state) =>
    state.user.userDetails ? state.user.userDetails.user : null
  );

  const handleLogout = () => {
    dispatch(userLogout(userToken)).then(() => {
      history.go(0);
    });
  };

  const { t, i18n } = useTranslation();

  return (
    <div style={{ background: '#fcfcfc' }}>
      <div className="header ps-0 ps-md-5 pe-0 pe-md-5 pt-2 pb-2 pt-md-0 pb-md-0">
        <Row style={{ height: 'auto' }} className="m-0">
          <div className="d-md-flex flex-md-row pt-1 pb-1 row-md">
            <div className="d-md-flex header-logo-div me-auto col-2 float-start float-md-none">
              <div>
                <img
                  style={{ height: 40, maxWidth: '100%', maxHeight: '100%' }}
                  src={Logo}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="d-md-flex flex-md-row col-10 ps-md-3 float-start float-md-none">
              <div className="header-btn-menu justify-content-md-end justify-content-center d-flex flex-md-row col-9 float-start float-md-none">
                <Link to="/home">
                  <div>
                    <HomeIcon />
                  </div>
                  <div>{t('Home')}</div>
                </Link>
                <Link to="/products">
                  <div>
                    <ProductsIcon />
                  </div>
                  <div>{t('Products')}</div>
                </Link>
                <Link to="/specialities">
                  <div>
                    <SpecialitiesIcon />
                  </div>
                  <div>{t('Specialities')}</div>
                </Link>
                <Link to="/users">
                  <div>
                    <UsersIcon />
                  </div>
                  <div>{t('Users')}</div>
                </Link>
                <Link to="/groups">
                  <div>
                    <GroupsIcon />
                  </div>
                  <div>{t('Groups')}</div>
                </Link>
              </div>
              <div className="d-flex header-user-div justify-content-end col-3 float-start float-md-none">
                <div
                  className={
                    props.userImg ? 'avatar-md' : 'user-avatar-div avatar-md'
                  }
                >
                  {props.userImg ? (
                    <img
                      src={props.userImg}
                      alt="User"
                      className="rounded-circle profile-img"
                    />
                  ) : (
                    <div>
                      {userDetails
                        ? userDetails.name[0].toUpperCase() +
                          userDetails.surname[0].toUpperCase()
                        : localStorage.getItem('name') &&
                          localStorage.getItem('surname')
                        ? localStorage.getItem('name')[0].toUpperCase() +
                          localStorage.getItem('surname')[0].toUpperCase()
                        : ''}
                    </div>
                  )}
                </div>
                <div className="text-primary d-flex">
                  <div style={{ maxWidth: '120px', overflow: 'hidden' }}>
                    {(userDetails
                      ? userDetails.name
                      : localStorage.getItem('name')
                      ? localStorage.getItem('name')
                      : '') +
                      ' ' +
                      (userDetails
                        ? userDetails.surname
                        : localStorage.getItem('surname')
                        ? localStorage.getItem('surname')
                        : '')}
                  </div>
                  <div className="ms-md-3 clickable">
                    <LogoutIcon onClick={handleLogout} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Header;
