import React, { useState, useEffect } from 'react';
import { Container, Input } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import proPic from './../../assets/images/propic.png';

import { users } from '../../constants/Data';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const UsersTable = (props) => {
  const { t, i18n } = useTranslation();

  const isLoading = props.loading === true ? true : false;
  const [pageNo, setPageNo] = useState(null);

  useEffect(() => {
    if (Math.ceil(props.total / 5) < pageNo) {
      setPageNo(Math.ceil(props.total / 5));
      props.getUsersList(Math.ceil(props.total / 5));
    }
  }, [props.total]);

  const columns = [
    {
      key: 'doc-row',
      text: '#',
      dataField: 'doc-row',
      formatter: (rowContent, row) => {
        return (
          <div className="user-avatar-div">
            {row.img ? (
              <img
                src={row.img ? row.img : proPic}
                alt="User"
                className="rounded-circle profile-img"
                style={{ maxHeight: 50, maxWidth: 50, minHeight: 50 }}
              />
            ) : (
              <div>
                {row.name[0].toUpperCase() + row.surname[0].toUpperCase()}
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'name',
      dataField: 'name',
      text: t('Name and Surname'),
      style: { fontWeight: 500 },
      formatter: (rowContent, row) => row.name + ' ' + row.surname,
    },
    {
      key: 'email',
      dataField: 'email',
      text: 'Email',
    },
    {
      key: 'role',
      dataField: 'role',
      text: t('Role'),
      style: { color: '#74788D' },
      formatter: (rowContent, row) =>
        users.find((el) => el.value === row.role).name,
    },
    {
      key: 'active',
      dataField: 'active',
      text: t('Active'),
      headerStyle: { width: 80, textAlign: 'center' },
      style: { maxWidth: 80, textAlign: 'center' },
      formatter: (rowContent, row) => {
        return (
          <Input
            type="checkbox"
            className="rounded-check"
            id="remember"
            name="remember"
            disabled={true}
            checked={row.active ? true : false}
            style={{
              borderRadius: 0,
              width: '1.22em',
              height: '1.2em',
              padding: 7,
              borderColor: '#DA1021',
              borderWidth: '.15rem',
              opacity: 0.9,
            }}
          />
        );
      },
    },
    {
      key: 'actions',
      dataField: 'actions',
      text: t('Actions'),
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
      formatter: (rowContent, row) => {
        return (
          <div className="text-primary">
            <span>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onEditClick(row)}
                className="bx bx-user-circle bx-sm"
              ></i>
            </span>
            <span>
              <i
                style={{ cursor: 'pointer' }}
                onClick={() => props.onDeleteClick(row.id)}
                className="bx bx-trash bx-sm"
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    sizePerPage: 10,
    page: pageNo ? pageNo : 1,
    withFirstAndLast: false,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    totalSize: props.total,
  });

  const onTableChange = (name, e) => {
    setPageNo(e.page);
    props.getUsersList(e.page);
  };

  return (
    <Container fluid={true} className="table-container">
      <BootstrapTable
        keyField="id"
        data={props.usersList}
        columns={columns}
        pagination={pagination}
        bordered={false}
        classes="table-responsive table-common user-table"
        headerClasses="header-class-dark-table"
        overlay={overlayFactory({
          spinner: true,
          styles: {
            spinner: (base) => ({
              ...base,
              '& svg circle': { stroke: '#DA1021' },
              width: '50px',
            }),
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.9)',
            }),
          },
        })}
        onTableChange={onTableChange}
        loading={isLoading}
        noDataIndication={<></>}
      />
    </Container>
  );
};

export default UsersTable;
