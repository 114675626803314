import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BackgroundImg from '../../components/landing/BackgroundImg';

import { resetUserPassword } from '../../redux/store/actions/userActions';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const userDetails = useSelector((state) => state.user);

  const handleResetPassword = (e) => {
    e.preventDefault();
    let data = {};
    data.password = password;
    data.token = token;

    dispatch(resetUserPassword(data)).then((res) => {
      if (res) {
        history.push('/reset-password-complete');
      }
    });
  };

  return (
    <div className="container">
      <BackgroundImg />

      <Row
        style={{ position: 'absolute', left: 0, margin: 0, width: '100%' }}
        className=" white-sq-container ps-5 pe-5 ps-md-0 pe-md-0"
      >
        <Col xl={4} md={6} className="mx-auto bg-white pt-2">
          <Row className="mb-3">
            <Col className="text-primary text-end">
              <Link to="/login">Go Back</Link>
            </Col>
          </Row>
          <Col className="ps-1 ps-md-5 pe-1 pe-md-5 pt-2 pt-md-4 pb-2 pb-md-4">
            <h4>Insert a new password!</h4>
            <form onSubmit={handleResetPassword}>
              <Row className="mt-4">
                <label htmlFor="password" className="mb-2">
                  New password
                </label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control form-control-login mb-2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="confirmPassword" className="mb-2">
                  Confirm New password
                </label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="form-control form-control-login"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Row>

              <Row className="mt-5 mb-4">
                <Button
                  type="submit"
                  color="primary"
                  disabled={password !== confirmPassword}
                >
                  Save new password
                </Button>
              </Row>
            </form>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
