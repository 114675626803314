import {
  
  GET_PRODUCTS_LIST_REQUEST,
  GET_PRODUCTS_LIST_SUCCEED,
  GET_PRODUCTS_LIST_FAILED,

  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCEED,
  ADD_PRODUCT_FAILED,

  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCEED,
  EDIT_PRODUCT_FAILED,

  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCEED,
  DELETE_PRODUCT_FAILED,


} from '../../ActionTypes';

import request from '../../../helpers/requestHelper';


export const getProductsList=(page)=> async dispatch=>{
	dispatch({type: GET_PRODUCTS_LIST_REQUEST})

	try{
		const res = await request({
			url: page ? `products?page=${page}&limit=20` : `products?limit=999`,
			auth: true,
			method:'GET',
		});

		if(res.code){
			dispatch({
				type:GET_PRODUCTS_LIST_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:GET_PRODUCTS_LIST_SUCCEED,
				payload: res
			});	
		}
	} catch (err){
		dispatch({
			type:GET_PRODUCTS_LIST_FAILED,
			payload: err
		});
	}
}


export const addProduct=(Data)=> async dispatch=>{
	dispatch({ type: ADD_PRODUCT_REQUEST });


	var data = new FormData();

	data.append('name', Data.name);
	data.append('description', Data.description);
	data.append('active', Data.active);

	if(Data.logo)
		data.append('logo', Data.logo);


	try{
		const res = await request({
			url:`products`,
			auth: true,
			method:'POST',
			data: data
		});

		if(res.code){
			dispatch({
				type:ADD_PRODUCT_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:ADD_PRODUCT_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:ADD_PRODUCT_FAILED,
			payload: err
		});
	}
}


export const editProduct=(Data)=> async dispatch=>{
	dispatch({ type: EDIT_PRODUCT_REQUEST });


	var data = new FormData();

	data.append('name', Data.name);
	data.append('description', Data.description);
	data.append('logo', Data.logo);
	data.append('active', Data.active);

	try{
		const res = await request({
			url:`products/${Data.id}`,
			auth: true,
			method:'PATCH',
			data: data
		});

		if(res.code){
			dispatch({
				type:EDIT_PRODUCT_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:EDIT_PRODUCT_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:EDIT_PRODUCT_FAILED,
			payload: err
		});
	}
}


export const deleteProduct=(id)=> async dispatch=>{
	dispatch({ type: DELETE_PRODUCT_REQUEST });

	try{
		const res = await request({
			url:`products/${id}`,
			auth: true,
			method:'DELETE',
		});

		if(res.code){
			dispatch({
				type:DELETE_PRODUCT_FAILED,
				payload: res
			});	
		}
		else{
			dispatch({
				type:DELETE_PRODUCT_SUCCEED,
				payload: res
			});	
		}

	} catch (err){
		dispatch({
			type:DELETE_PRODUCT_FAILED,
			payload: err
		});
	}
}