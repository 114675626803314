import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Breadcrumb from '../../components/common/Breadcrumb'
import SpecialitiesTable from '../../components/main/SpecialitiesTable'
import SearchBar from '../../components/main/SearchBar'
import ProductSpecialityModal from '../../components/main/ProductSpecialityModal'
import DeleteModal from '../../components/main/DeleteModal'

import { getSpecialitiesList, addSpeciality, editSpeciality, deleteSpeciality } from '../../redux/store/actions/specialityActions'

import request from '../../helpers/requestHelper';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Specialities=(props)=>{

	const { t, i18n } = useTranslation();

	const dispatch = useDispatch();
	const history = useHistory()
  	const speciality = useSelector((state)=> state.speciality);

  	const [specialtiesData, setSpecialtiesData] = useState([])
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formState, setFormState] = useState({
		name:'',
		description:'',
		logo:'',
		active:true
	})
	const [deleteId, setDeleteId] = useState()
	const [specialitySearch, setSpecialitySearch] = useState()
	const [specialityPics, setSpecialityPics] = useState([])
	const [previewUrl, setPreviewUrl] = useState();

	useEffect(()=>{
		dispatch(getSpecialitiesList(1))
	},[])

	useEffect(()=>{
		setSpecialtiesData(speciality.specialitiesList);
		if(speciality.specialitiesList.length){
			getPicture()
		}
	},[JSON.stringify(speciality.specialitiesList)])

	useEffect(()=>{
		if(speciality.errorMsg && speciality.errorMsg.code === 401)
			history.go(0);
	},[speciality.errorMsg])

	const getPicture= async ()=>{

		let oldData = JSON.parse(JSON.stringify(speciality.specialitiesList));

		for(var i = 0; i < speciality.specialitiesList.length; i++){
			if(speciality.specialitiesList[i].logo){
				const res = await request({
					url:`files/${speciality.specialitiesList[i].logo}/type/specialties`,
					auth: true,
					method:'GET',
				});
				if(!res.code){
					var objectURL = URL.createObjectURL(res);
					oldData[i].img = objectURL;
				}					
			}
		}
		setSpecialtiesData(oldData)
	}

	const onNewButtonClick=()=>{
		if(!formState.active){
			setFormState({
				...formState,
				active:true
			})
		}
		setIsModalOpen(true);
	}

	const onEditClick=(item)=>{
		setFormState({
			name: item.name,
			description: item.description,
			active: item.active,
			id: item.id,
			logo: item.logo,
		})
		getSinglePicture(item.logo)
		setIsModalOpen(true)
	}

	const onSaveClick=(e)=>{
		setIsModalOpen(false)
		e.preventDefault();

	    let data = {};

	    data.name = formState.name;
	    data.description = formState.description;
	    data.logo = formState.logo;
	    data.active = formState.active;

	    if(formState.id){
	    	data.id = formState.id;
	    	dispatch(editSpeciality(data));
	    }
	    else
	    	dispatch(addSpeciality(data));

	    setFormState({})
	}

	const onDeleteClick=(id)=>{
		setDeleteId(id)
	}

	const handleDelete=()=>{
		let id = deleteId;
		setDeleteId()

		dispatch(deleteSpeciality(id))
	}

	const getSinglePicture= async (logoName)=>{

		if(logoName){
			const res = await request({
				url:`files/${logoName}/type/specialties`,
				auth: true,
				method:'GET',
			});
			if(!res.code){
				var objectURL = URL.createObjectURL(res);
				setPreviewUrl(objectURL)
			}					
		}
	}

	return(
		<div>
			<Breadcrumb title='Home' breadcrumbItem={t('Specialities')} breadcrumbItemName={t('Specialities')} />
			<SearchBar 
				name='Specialities' 
				btnName='Speciality' 
				onClick={onNewButtonClick}
				searchValue={specialitySearch}
				setSearchValue={setSpecialitySearch}
			/>
			<SpecialitiesTable 
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
				specialitiesList={specialitySearch ? specialtiesData.filter((el) => el.name.toLowerCase().includes(specialitySearch.toLowerCase()) || el.description.toLowerCase().includes(specialitySearch.toLowerCase())) : specialtiesData}
				specialityPics={specialityPics}
				total={speciality.total}
				loading={speciality.subLoading}
				getSpecialitiesList={(pageNo)=> dispatch(getSpecialitiesList(pageNo))}
			/>
			<ProductSpecialityModal 
				formState={formState}
				setFormState={setFormState}
				isOpen={isModalOpen} 
				setIsOpen={setIsModalOpen} 
				name='Speciality'
				onSaveClick={onSaveClick}
				previewUrl={previewUrl}
				setPreviewUrl={setPreviewUrl}
			/>
			<DeleteModal
				isOpen={deleteId ? true : false} 
				setIsOpen={setDeleteId}
				name='speciality'
				onDelete={handleDelete}
			/>
		</div>
	)
}

export default Specialities;