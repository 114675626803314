import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
  Container,
  Button,
} from 'reactstrap';

import Select from 'react-select';

import { useTranslation } from 'react-i18next';

const GroupModal = (props) => {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedGroupLeaderOptions, setSelectedGroupLeader] = useState("");

  useEffect(() =>{
    if(props.formState?.id){

      let leader = props.usersList.find((el) => el.id === props.formState.leader);
      let members = props.formState.members?.map((el) => props.usersList.find((el2) => el2.id === el));

      setSelectedGroupLeader(leader ? leader : "")
      setSelectedOptions(members.filter((el) => el?.id))
    }
  }, [props.isOpen])

  const toggleModal = () => {
    props.setIsOpen(!props.isOpen);
    props.setFormState({
      ...props.formState,
      name: '',
      leader: '',
      members: [],
      active: true,
    });
  };

  const onSaveClick=(e)=>{
    e.preventDefault();

    const leader = selectedGroupLeaderOptions?.id;
    const members = selectedOptions.map((el) => el.id);

    setSelectedGroupLeader("")
    setSelectedOptions([])
    props.onSaveClick(leader, members);
  }

  const handleLeaderChange = (selectedOptions) => {
    setSelectedGroupLeader(selectedOptions);
  };

  const handleMembersChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const onChangeHandler = (e) => {
    const { name, value, type } = e.target;
    props.setFormState({
      ...props.formState,
      [name]: type === 'checkbox' ? !props.formState.active : value,
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      data-toggle="modal"
      toggle={toggleModal}
    >
      <ModalHeader>{t('Add / Edit group')}</ModalHeader>
      <ModalBody>
        <Container>
          <form onSubmit={onSaveClick}>
            <Row>
              <div className="mt-3 col-12">
                <div>
                  <label className="mb-2 p-0" htmlFor="name">
                    {t('Group Name')} *
                  </label>
                </div>
                <div>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={props.formState.name}
                    onChange={onChangeHandler}
                    required
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="mt-3 col-12">
                <div>
                  <label className="mb-2 p-0" htmlFor="leader">
                    {t('Select group leader')}
                  </label>
                </div>
                <Select
                  name="leader"
                  id="leader"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={props.formState.leader}
                  value={selectedGroupLeaderOptions}
                  options={props.usersList.filter((el) => (el.role !== 'int') )}
                  getOptionLabel={(option) => option.name + ' ' + option.surname}
                  getOptionValue={(option) => `${option['id']}`}
                  onChange={handleLeaderChange}
                />
              </div>
            </Row>
            <Row>
              <div className="mt-3 col-12">
                <div>
                  <label className="mb-2 p-0" htmlFor="group">
                    {t('Select groups members')}
                  </label>
                </div>
                <Select
                  isMulti
                  name="group"
                  id="group"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  defaultValue={props.formState.members}
                  value={selectedOptions}
                  options={props.usersList.filter((el) => (el.role !== 'admin' && el.role !== 'int') )}
                  getOptionLabel={(option) => option.name + ' ' + option.surname}
                  getOptionValue={(option) => `${option['id']}`}
                  onChange={handleMembersChange}
                />
              </div>
            </Row>
            <Row>
              <div
              className=" form-check my-3"
              style={{
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Input
                type="checkbox"
                className="rounded-check"
                id="active"
                name="active"
                style={{ width: '1.22em', height: '1.2em' }}
                checked={props.formState.id ? props.formState.active : true}
                onChange={props.formState.id ? onChangeHandler : null}
                readOnly={!props.formState.id}
              />
              <label
                className="form-check-label ms-2"
                style={{ marginTop: 1 }}
                htmlFor="active"
              >
                {t('Active')}
              </label>
            </div>
            </Row>
            <Row>
              <div className="text-end mt-3">
                <Button
                  type="submit"
                  disabled={!selectedGroupLeaderOptions}
                  className="btn-sm me-2"
                  color="primary"
                >
                  {t('Save')} {t(props.name)}
                </Button>
                <Button type="button" className="btn-sm" onClick={toggleModal}>
                  {t('Cancel')}
                </Button>
              </div>
            </Row>
          </form>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default GroupModal;
