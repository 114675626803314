import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import user from './store/reducers/userReducer';
import product from './store/reducers/productReducer';
import speciality from './store/reducers/specialityReducer';
import document from './store/reducers/documentReducer';
import group from './store/reducers/groupReducer';

const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      user,
      product,
      speciality,
      document,
      group,
    }),
    applyMiddleware(thunk)
  );

  return store;
};

export const store = ConfigureStore();
