import React from 'react';
import { Row, Col } from 'reactstrap';
import BackgroundImg from '../../components/landing/BackgroundImg';

const ResetPassword = (props) => {
  return (
    <div className="container">
      <BackgroundImg />

      <Row
        style={{ position: 'absolute', left: 0, margin: 0, width: '100%' }}
        className=" white-sq-container ps-5 pe-5 ps-md-0 pe-md-0"
      >
        <Col xl={4} md={6} className="mx-auto bg-white pt-2">
          <Col className="ps-1 ps-md-5 pe-1 pe-md-5 pt-2 pt-md-4 pb-2 pb-md-4 text-center">
            <h4 className="mb-4">
              <strong>Password cambiata con successo!</strong>
            </h4>
            <p>
              Ora puoi accedere all’applicazione con la nuova password appena
              impostata.
            </p>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
