import {
  
  GET_SPECIALITIES_LIST_REQUEST,
  GET_SPECIALITIES_LIST_SUCCEED,
  GET_SPECIALITIES_LIST_FAILED,

  ADD_SPECIALITY_REQUEST,
  ADD_SPECIALITY_SUCCEED,
  ADD_SPECIALITY_FAILED,

  EDIT_SPECIALITY_REQUEST,
  EDIT_SPECIALITY_SUCCEED,
  EDIT_SPECIALITY_FAILED,

  DELETE_SPECIALITY_REQUEST,
  DELETE_SPECIALITY_SUCCEED,
  DELETE_SPECIALITY_FAILED,

} from '../../ActionTypes';


const initialState ={
	errorMsg: null,
	successMsg: null,
	specialitiesList: [],
	isLoading: false,
	subLoading: true,
	total:0,
}


export default function userData(state = initialState, action){
	const {type, payload} = action;
	switch(type){
		
		case GET_SPECIALITIES_LIST_REQUEST:
			return {
				...state,
				subLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case ADD_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case EDIT_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};
		case DELETE_SPECIALITY_REQUEST:
			return {
				...state,
				isLoading:true,
				errorMsg:null,
				successMsg: null,
			};


		case GET_SPECIALITIES_LIST_SUCCEED:
			return{
				...state,
				successMsg: 'Speciality List Fetched',
				specialitiesList: payload.results && payload.results.length ? payload.results : [],
				subLoading: false,
				total: payload.totalResults,				
			};
		case GET_SPECIALITIES_LIST_FAILED:
			return{
				...state,
				specialitiesList: [],
				subLoading:false,
				errorMsg:payload,
				total:0
			};


		case ADD_SPECIALITY_SUCCEED:
			return{
				...state,
				successMsg: 'Speciality Added',
				specialitiesList: state.total%5 !== 0 ? state.specialitiesList.concat(payload) : state.specialitiesList,
				isLoading: false,
				total:state.total +1,
			};
		case ADD_SPECIALITY_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};


		case EDIT_SPECIALITY_SUCCEED:
			let specialities = state.specialitiesList;
      		let specialityIndex = state.specialitiesList.findIndex(item => item.id === payload.id);
      		
      		specialities.splice(specialityIndex, 1, { ...state.specialitiesList[specialityIndex], ...payload })

			return{
				...state,
				successMsg: 'Speciality Edited',
				specialitiesList: specialities,
				isLoading: false
			};
		case EDIT_SPECIALITY_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};


		case DELETE_SPECIALITY_SUCCEED:
			return{
				...state,
				successMsg: 'Speciality Deleted',
				specialitiesList: state.specialitiesList.filter((el)=>el.id !== payload),
				isLoading: false,
				total:state.total -1,
			};
		case DELETE_SPECIALITY_FAILED:
			return{
				...state,
				isLoading:false,
				errorMsg:payload
			};

		default:
			return state;
	}
}