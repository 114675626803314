import React, { useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
  Container,
  Button,
} from 'reactstrap';
import { FileDrop } from 'react-file-drop';
import { useSelector } from 'react-redux';

import Loading from '../common/OverlaySpinner';

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const ProductDocModal = (props) => {
  const { t, i18n } = useTranslation();

  // const documentDetail = useSelector((state)=> state.document);
  const product = useSelector((state) => state.product);

  const fileInputRef = useRef(null);

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const toggleModal = () => {
    props.setIsOpen(!props.isOpen);
    props.setFormState({
      product: '',
      associatedDocument: null,
    });
  };

  const onFileDrop = (files, event) => {
    for (var i = 0; i < files.length; i++) {
      let exe = files[i].name.split('.').pop();
      if (exe === 'pdf') {
        props.setFormState({
          ...props.formState,
          associatedDocument: files[i],
        });
        break;
      }
    }
  };

  const onChangeHandler = (e) => {
    const { name, value, type } = e.target;
    props.setFormState({
      ...props.formState,
      [name]: value,
    });
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    props.setFormState({
      ...props.formState,
      associatedDocument: files[0],
    });
  };

  return (
    <Modal
      isOpen={props.isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      data-toggle="modal"
      toggle={toggleModal}
    >
      <Loading isLoading={product.isLoading} tableLoader={true} />
      <ModalHeader>{t('Add / Edit Product’s Document')}</ModalHeader>
      <ModalBody>
        <Container>
          <form onSubmit={props.onSaveClick}>
            <div>
              <div>
                <label className="mb-2 p-0 fw-semi-bold" htmlFor="productName">
                  {t('Select a Product')} *
                </label>
              </div>
              <div>
                <Input
                  type="select"
                  name="product"
                  id="product"
                  className="form-control"
                  style={{ background: '#FFFFFF' }}
                  value={props.formState.product}
                  onChange={onChangeHandler}
                  required
                >
                  <option hidden value="">
                    {t('Select a Product')}
                  </option>
                  {product.productsList.length &&
                    product.productsList.map((item, id) => {
                      return (
                        <option key={id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </Input>
              </div>
            </div>
            <div className="mt-3">
              <div>
                <label
                  className="mb-2 p-0 fw-semi-bold"
                  htmlFor="associatedDocument"
                >
                  {t('PDF Document')} *
                </label>
              </div>
              <div>
                <input
                  type="file"
                  name="associatedDocument"
                  id="associatedDocument"
                  accept=".pdf"
                  ref={fileInputRef}
                  onChange={onFileInputChange}
                  style={{ display: 'none' }}
                />
                {props.formState.associatedDocument ? (
                  <FileDrop onTargetClick={onTargetClick} onDrop={onFileDrop}>
                    <div>
                      <i className="bx bxs-file-pdf text-primary bx-lg"></i>
                      <div>
                        {props.formState.associatedDocument.name
                          ? props.formState.associatedDocument.name
                          : props.formState.associatedDocument}
                      </div>
                    </div>
                  </FileDrop>
                ) : (
                  <FileDrop onTargetClick={onTargetClick} onDrop={onFileDrop}>
                    <div>
                      <div className="text-center">
                        <UploadIcon />
                      </div>
                      <div>
                        {t(
                          'Drag a file in this area or select it from your device'
                        )}
                      </div>
                    </div>
                  </FileDrop>
                )}
              </div>
            </div>
            <div className="mt-3">
              <div>
                <label className="mb-2 p-0 fw-semi-bold" htmlFor="page">
                  {t('Page')} # *
                </label>
              </div>
              <div>
                <Input
                  type="telno"
                  name="page"
                  id="page"
                  className="form-control"
                  value={props.formState.page}
                  onChange={onChangeHandler}
                  required
                />
              </div>
            </div>
            <Row>
              <div className="text-end mt-4">
                <Button
                  disabled={props.formState.associatedDocument ? false : true}
                  className="btn-sm me-2"
                  type="submit"
                  color="primary"
                >
                  {t('Save Product')}
                </Button>
                <Button className="btn-sm" type="button" onClick={toggleModal}>
                  {t('Cancel')}
                </Button>
              </div>
            </Row>
          </form>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ProductDocModal;
