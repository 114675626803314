import React, { useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Input, Container, Button } from 'reactstrap' 
import { FileDrop } from 'react-file-drop'
import { useSelector } from 'react-redux'

import Loading from '../common/OverlaySpinner'

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg' 

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const SpecialityLinkModal=(props)=>{

	const { t, i18n } = useTranslation();

  	const speciality = useSelector((state)=> state.speciality);
	const productsList = useSelector((state)=> state.product.productsList);

	const fileInputRef = useRef(null);

	const onFileInputChange = (event) => {
		const { files } = event.target;
	  	props.setFormState({
			...props.formState,
			coverImage: files[0]
		})

		const fileReader = new FileReader()
        fileReader.onload = () => {
            props.setPreviewUrl(fileReader.result)
        }
        fileReader.readAsDataURL(files[0])
	}

	const onTargetClick = () => {
		fileInputRef.current.click()
	}

	const toggleModal=()=>{
		props.setIsOpen(!props.isOpen)
		props.setFormState({
			product:'',
			specialty:'',
			contact:'',
			page:'',
			order:'',
			coverImage:null,
			active: true,
		});
		props.setPreviewUrl()
	}

	const onChangeHandler=(e)=>{
		const { name, value, type } = e.target;
		props.setFormState({
			...props.formState,
			[name]: type === 'checkbox' ? !props.formState.active : value
		})
	}

	const onFileDrop=(files, event) => {
		for (var i = 0; i < files.length; i++) {
			let exe = files[i].name.split('.').pop()
			if(exe === 'jpg' || exe === 'jpeg' || exe === 'png'){
				props.setFormState({
					...props.formState,
					coverImage: files[i]
				})

				const fileReader = new FileReader()
		        fileReader.onload = () => {
		            props.setPreviewUrl(fileReader.result)
		        }
		        fileReader.readAsDataURL(files[i])
				break;
			}
		}
	}

	return(
		<Modal
	        isOpen={props.isOpen}
	        role="dialog"
	        autoFocus={true}
	        centered={true}
	        tabIndex="-1"
	        data-toggle="modal"
	        toggle={toggleModal}
	      >
	        <ModalHeader>{t('Add / Edit Speciality Link')}</ModalHeader>
	        <ModalBody>
	        	<Container>
	        		<form onSubmit={props.onSaveClick}>
		        		<div>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='productName'>{t('Select a Speciality')} *</label></div>
			        		<div>
			        			<Input
				                  type='select'
				                  name='specialty'
				                  id='specialty'
				                  className='form-control' 
				                  value={props.formState.specialty}
				                  onChange={onChangeHandler}
				                  required 
				                >
				                	<option hidden value=''>{t('Select a Speciality')}</option>
				                	{speciality.specialitiesList.length && speciality.specialitiesList.map((item, id)=>{
				                		return(
				                			<option key={id} value={item.id}>{item.name}</option>
				                		)
				                	})}
		                        </Input> 
			        		</div>
		        		</div>
		        		<div className='mt-3'>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='productName'>{t('Select a Product')} *</label></div>
			        		<div>
			        			<Input
				                  type='select'
				                  name='product'
				                  id='product'
				                  className='form-control' 
				                  value={props.formState.product}
				                  onChange={onChangeHandler}
				                  required 
				                >
				                	<option hidden value=''>{t('Select a Product')}</option>
				                	{props.products.length && props.products.map((item, id)=>{
				                		return(
				                			<option key={id} value={item.product}>{productsList.find((el)=> el.id === item.product) ? productsList.find((el)=> el.id === item.product).name : item.product}</option>
				                		)
				                	})}
		                        </Input> 
			        		</div>
		        		</div>
		        		<div className='mt-3'>
			        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='coverImage'>{t('Cover Image')}</label></div>
			        		<div>
			        			<input
				                  type='file'
				                  name='coverImage'
				                  id='coverImage'
				                  accept='.jpg, .jpeg, .png'
				                  ref={fileInputRef}
				                  onChange={onFileInputChange}
				                  style={{display:'none'}} 
				                /> 
				                {(props.formState.coverImage && props.previewUrl) ? 
					                <FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >
					                	<div>
					                		<img style={{maxHeight:'90px'}} src={props.previewUrl} />
					                	</div>
					                </FileDrop>
					        	
					        	:

					        	props.formState.coverImage && !props.previewUrl ? 
				                	<FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >
					                	<div>
					                		<i className='bx bxs-image text-primary bx-lg'></i>
					                		<div>{props.formState.coverImage.name ? props.formState.coverImage.name : props.formState.coverImage}</div>
					                	</div>
					                </FileDrop>
					            :
					                <FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >	
					                	<div>
					                		<div className='text-center'><UploadIcon/></div>
					                		<div>{t('Drag a file in this area or select it from your device')}</div>
					                	</div>
					                </FileDrop>
					            }
			        		</div>
		        		</div>
		        		<Row>
		        			<div className='col-md-6 mt-3'>
				        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='page'>{t('Page')} # *</label></div>
				        		<div>
				        			<Input
					                  type='telno'
					                  name='page'
					                  id='page'
					                  className='form-control' 
					                  value={props.formState.page}
					                  onChange={onChangeHandler}
					                  required 
					                />
				        		</div>
			        		</div>
			        		<div className='col-md-6 mt-3'>
				        		<div><label className='mb-2 p-0 fw-semi-bold' htmlFor='page'>{t('Contact')} # *</label></div>
				        		<div>
				        			<Input
					                  type='telno'
					                  name='contact'
					                  id='contact'
					                  className='form-control' 
					                  value={props.formState.contact}
					                  onChange={onChangeHandler}
					                  required 
					                />
				        		</div>
			        		</div>
		        		</Row>
		        		<div className="mt-3 form-check">
			                <Input
			                  type="checkbox"
			                  className="rounded-check"
			                  id="active"
			                  name='active'
			                  style={{width:'1.22em', height:'1.2em'}}
			                  checked={props.formState.active}
		                  	  onChange={onChangeHandler}
			                />
			                <label className="form-check-label ms-2" style={{marginTop:1}} htmlFor="active">
								{t('active inside the document')}
			                </label>
			            </div>
		        		<Row>
				            <div className='text-end mt-4'>
				            	<Button disabled={props.formState.coverImage ? false : true} type='submit' className='btn-sm me-2' color='primary'>{t('Save Link')}</Button>
				            	<Button className='btn-sm' type='button' onClick={toggleModal}>{t('Cancel')}</Button>
				            </div>
			            </Row>
			        </form>
	        	</Container>
	        </ModalBody>
	    </Modal>
	)
}


export default SpecialityLinkModal;