import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Input,
  Container,
  Button,
} from 'reactstrap';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const WarningModal = (props) => {
  const { t, i18n } = useTranslation();

  const toggleModal = () => {
    props.setIsOpen(!props.isOpen);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      role="dialog"
      autoFocus={true}
      tabIndex="-1"
      data-toggle="modal"
      toggle={toggleModal}
    >
      <ModalHeader toggle={toggleModal}>Warning!</ModalHeader>
      <ModalBody>
        <Container>
          <div className="font-size-14">
            <div>
              {t(
                'There are links attached to this document, please remove them before continuing with the operation.'
              )}
            </div>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default WarningModal;
