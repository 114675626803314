import React, { useState, useEffect } from 'react'
import { Container, Row } from 'reactstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux'

import AdditionalLinkModal from './AdditionalLinkModal'
import DeleteModal from '../main/DeleteModal'

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg' 

import request from '../../helpers/requestHelper';

import { addDocumentAdditionalLink, deleteDocumentAdditionalLink, editDocumentAdditionalLink, updateDocumentAdditionalLinkOrder } from '../../redux/store/actions/documentActions'

import { useTranslation, withTranslation, Trans } from 'react-i18next';

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Icon } from '@iconify/react';

const AdditionalLinks=(props)=>{

	const { t, i18n } = useTranslation();
	
	const dispatch = useDispatch();

	const [deleteId, setDeleteId] = useState()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formState, setFormState] = useState({
		title:'',
		page:'',
		order:'',
		coverImage:null,
		associatedDocument:null,
		active: true,
	});
	const [previewUrl, setPreviewUrl] = useState();

	const [Data, setData] = useState([])

	useEffect(()=>{
		setData(props.additionalLinks.sort((a,b)=> {return a.order - b.order}))
	},[JSON.stringify(props.additionalLinks)])

	const getSinglePicture= async (imgName)=>{

		if(imgName){
			const res = await request({
				url:`files/${props.documentId}/file/${imgName}`,
				auth: true,
				method:'GET',
			});
			if(!res.code){
				var objectURL = URL.createObjectURL(res);
				setPreviewUrl(objectURL)
			}					
		}
	}

	const saveAdditionalLink=(e)=>{
		e.preventDefault();
		setIsModalOpen(false);

		let data = {};

		data.docId = props.documentId;
	    data.title = formState.title;
	    data.page = formState.page;
	    data.active = formState.active;
	    data.order = formState.order ? formState.order : props.additionalLinks.length + 1;

	    if(formState.coverImage && formState.coverImage.name)
	    	data.coverImage = formState.coverImage;
	    if(formState.associatedDocument && formState.associatedDocument.name)
		    data.associatedDocument = formState.associatedDocument;

	    if(formState.id){
	    	data.id = formState.id;
	    	dispatch(editDocumentAdditionalLink(data))
	    }
	    else
	    	dispatch(addDocumentAdditionalLink(data))
	   	
	   	setFormState({
			title:'',
			page:'',
			order:'',
			coverImage:null,
			associatedDocument:null,
			active:true,
		})

	}

	const handleDelete=()=>{
		let id = deleteId;
		setDeleteId()

		dispatch(deleteDocumentAdditionalLink(props.documentId, id))
	}

	const handleEdit=(item)=>{
		setFormState({
			coverImage: item.coverImageFileName,
			associatedDocument: item.associatedDocumentFileName,
			title: item.title,
			page: item.page,
			id: item._id,
			order: item.order,
			active: item.active
		})
		getSinglePicture(item.coverImage)
		setIsModalOpen(true)
	}

	const onDragEnd=(e)=>{
		const { destination, source, draggableId } = e;

	    if (!destination) {return;}
	    else if (destination.droppableId === source.droppableId && destination.index === source.index) {return;}
		else{
			const newData = JSON.parse(JSON.stringify(Data));
			const element = Data[Number(draggableId)]
			newData.splice(source.index, 1);
			newData.splice(destination.index, 0, element);
			setData(newData)
			let order = {};
			for(var i = 0; i < newData.length; i++){
				order[i] = newData[i]._id;
			}
			dispatch(updateDocumentAdditionalLinkOrder(props.documentId, order))
		}
	}

	const getItemStyle = (isDragging, draggableStyle) => ({
	  display: isDragging ? "table" : "",
	  ...draggableStyle
	});

	const tooltip = (
		<Tooltip id="tooltip">
		  <strong>{t('Missing file!')}</strong>
		</Tooltip>
	  );

	return(
		<>
			<Container fluid={true} className='p-3'>
				<Row><div className='fw-bold'>{t('Additional Links')} <span onClick={()=>setIsModalOpen(true)} className='ms-2 clickable'><PlusIcon/></span></div></Row>
				{Data.length ?
					<Row className='mt-3'>
						<div className='table-container'>
							<DragDropContext onDragEnd={onDragEnd}>
								<table style={{width:'100%'}} className='document-edit-table'>
									<thead>
										<tr>
											<th>{t('Order')}</th>
											<th>{t('Link Title')}</th>
											<th>{t('PDF Document')}</th>
											<th>{t('Cover Image')}</th>
											<th>{t('Page')} #</th>
											<th>{t('Actions')}</th>
										</tr>
									</thead>
									<Droppable droppableId="table-2">
										{(provided, snapshot) => (
											<tbody ref={provided.innerRef} {...provided.droppableProps}>
												{Data.map((item, id)=>{
													return(
														<Draggable draggableId={id.toString()} index={id} key={id}>
															{(provided, snapshot) => (
																<tr 
																	ref={provided.innerRef}
																	{...provided.draggableProps}
										                            key={id}
										                            style={getItemStyle(
											                          snapshot.isDragging,
											                          provided.draggableProps.style
											                        )}
																>
																	<td><div className='text-primary'><i {...provided.dragHandleProps} style={{border:'1px solid #da1021', borderRadius:5}} className='bx bx-menu clickable'></i></div></td>
																	<td><div className='form-control'>{item.title}</div></td>
																	<td>
																		{item.associatedDocumentFileName ? <div className='form-control'>{item.associatedDocumentFileName}</div> : <div className='form-control d-flex justify-content-between align-items-center'><span>{t('No PDF file found')}</span><OverlayTrigger placement="top" overlay={tooltip}><Icon icon="material-symbols:warning-outline-rounded" color="#da1021" width="18" height="18"/></OverlayTrigger></div>}
																	</td>
																	<td><div className='form-control'>{item.coverImageFileName}</div></td>
																	<td><div className='form-control'>{item.page}</div></td>
																	<td>
																		<div className='text-primary'>
													        				<span><i onClick={()=> handleEdit(item)} className='bx bx-file clickable'></i></span>
													        				<span><i onClick={()=> setDeleteId(item._id)} className='bx bx-trash clickable'></i></span>
													        			</div>
																	</td>
																</tr>
															)}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</tbody>
										)}
									</Droppable>
								</table>
							</DragDropContext>
						</div>
					</Row>
				:''}
			</Container>

			<AdditionalLinkModal 
				isOpen={isModalOpen} 
				setIsOpen={setIsModalOpen}
				formState={formState}
				setFormState={setFormState}
				onSaveClick={saveAdditionalLink}
				previewUrl={previewUrl}
				setPreviewUrl={setPreviewUrl}
			/>

			<DeleteModal
				isOpen={deleteId ? true : false} 
				setIsOpen={setDeleteId}
				name='additional link'
				onDelete={handleDelete}
			/>
		</>
	)
}

export default AdditionalLinks