import React, { useRef } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Input, Container, Button } from 'reactstrap' 
import { FileDrop } from 'react-file-drop'

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg' 

import { useTranslation, withTranslation, Trans } from 'react-i18next';

const ProductSpecialityModal=(props)=>{

	const { t, i18n } = useTranslation();

  	const fileInputRef = useRef(null);

	const onFileInputChange = (event) => {
		const { files } = event.target;
	  	props.setFormState({
			...props.formState,
			logo: files[0]
		})

		const fileReader = new FileReader()
        fileReader.onload = () => {
            props.setPreviewUrl(fileReader.result)
        }
        fileReader.readAsDataURL(files[0])
	}

	const onTargetClick = () => {
		fileInputRef.current.click()
	}

	const toggleModal=()=>{
		props.setIsOpen(!props.isOpen)
		props.setFormState({
			name:'',
			description:'',
			logo:'',
			active:''
		})
		props.setPreviewUrl()
	}

	const onChangeHandler=(e)=>{
		const { name, value, type } = e.target;
		props.setFormState({
			...props.formState,
			[name]: type === 'checkbox' ? !props.formState.active : value
		})
	}

	const onFileDrop=(files, event) => {
		for (var i = 0; i < files.length; i++) {
			let exe = files[i].name.split('.').pop()
			if(exe === 'jpg' || exe === 'jpeg' || exe == 'png'){
				props.setFormState({
					...props.formState,
					logo: files[i]
				})

				const fileReader = new FileReader()
		        fileReader.onload = () => {
		            props.setPreviewUrl(fileReader.result)
		        }
		        fileReader.readAsDataURL(files[i])
				break;
			}
		}
	}

  return(
      <Modal
        isOpen={props.isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        tabIndex="-1"
        data-toggle="modal"
        toggle={toggleModal}
      >
        <ModalHeader> {t('Add / Edit')} {t(props.name)}</ModalHeader>
        <ModalBody>
        	<Container>
        		<form onSubmit={props.onSaveClick}>
	        		<div>
		        		<div><label className='mb-2 p-0' htmlFor='name'>{t('Name')} *</label></div>
		        		<div>
		        			<Input
			                  type='text'
			                  name={t('name')}
			                  id='name'
			                  className='form-control' 
			                  value={props.formState.name}
			                  onChange={onChangeHandler}
			                  required
			                /> 
		        		</div>
	        		</div>
	        		<div className='mt-3'>
		        		<div><label className='mb-2 p-0' htmlFor='description'>{t('Description')}</label></div>
		        		<div>
		        			<Input
			                  type='textarea'
			                  rows={5}
			                  name={t('description')}
			                  id='description'
			                  className='form-control' 
			                  value={props.formState.description}
			                  onChange={onChangeHandler}
			                /> 
		        		</div>
	        		</div>
	        		<div className='mt-3'>
		        		<div><label className='mb-2 p-0' htmlFor='logo'>{t('Logo')}</label></div>
		        		<div>
		        			<input
			                  type='file'
			                  name={t('logo')}
			                  id='logo'
			                  accept='.jpg, .jpeg, .png'
			                  ref={fileInputRef}
			                  onChange={onFileInputChange}
			                  style={{display:'none'}} 
			                /> 
			                {(props.formState.logo && props.previewUrl) ? 
					                <FileDrop 
					                	onTargetClick={onTargetClick}
					                	onDrop={onFileDrop}
					                >
					                	<div>
					                		<img style={{maxHeight:'90px'}} src={props.previewUrl} />
					                	</div>
					                </FileDrop>
					        :

					            props.formState.logo && !props.previewUrl ? 
				                
				                <FileDrop 
				                	onTargetClick={onTargetClick}
				                	onDrop={onFileDrop}
				                >
				                	<div>
				                		<i className='bx bxs-image text-primary bx-lg'></i>
				                		<div>{props.formState.logo.name ? props.formState.logo.name : props.formState.logo}</div>
				                	</div>
				                </FileDrop>
				            :

				                <FileDrop 
				                	onTargetClick={onTargetClick}
				                	onDrop={onFileDrop}
				                >
				                	<div>
				                		<div className='text-center'><UploadIcon/></div>
				                		<div>{t('Drag a file in this area or select it from your device')}</div>
				                	</div>
				                </FileDrop>
				            }
		        		</div>
	        		</div>
	    			<div className="form-check p-0 mt-2" style={{fontWeight:500, display:'flex', justifyContent:'end'}}>
		                <Input
		                  type="checkbox"
		                  className="rounded-check"
		                  id="active"
		                  name='active'
		                  style={{width:'1.22em', height:'1.2em'}}
		                  checked={props.formState.active}
		                  onChange={onChangeHandler}
		                />
		                <label className="form-check-label ms-2" style={{marginTop:1}} htmlFor="active">
							{t('Active')}
		                </label>
		            </div>
		            <Row>
		            <div className='text-end mt-3'>
		            	<Button type='submit' className='btn-sm me-2' color='primary'>{t('Save')} {t(props.name)}</Button>
		            	<Button type='button' className='btn-sm' onClick={toggleModal}>{t('Cancel')}</Button>
		            </div>
		            </Row>
	            </form>
        	</Container>
        </ModalBody>
      </Modal>
  )
}

export default ProductSpecialityModal;
